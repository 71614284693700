import { FormSubmissionButton, CustomButton, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import {readNestedErrorByPath} from '../../../../../helpers';

import {useEffect   } from 'react';

const VehicleAsset = ({prefix , errors, setFieldValue , assetOwnershipList , states}) => {

    useEffect(() => {
        //UPDATE THE STRUCTURE BY USING THE SELECTED ASSET TYPE 
       // setFieldValue("assets[" + index + "].assetType", assetType , "")
       /*setFieldValue(prefix+"assetValue", 0);
       setFieldValue(prefix+"assetOwnership", "");
       setFieldValue(prefix+"make", "");
       setFieldValue(prefix+"model", "");
       setFieldValue(prefix+"year", "");
       setFieldValue(prefix+"vin", "");
       setFieldValue(prefix+"state", "");*/

    }, [])


    return (
        <>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Asset Value</label>
                <Field name= { prefix+"assetValue"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"assetValue"} 
                            fieldValue = {field.value !== undefined ? field.value : 0 } 
                            placeholder = "Asset Value" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field> 
                { readNestedErrorByPath(errors, prefix+"assetValue").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"assetValue") } </div> : "" }
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Asset Ownership</label>
                <Field name=  {prefix+"assetOwnership"}>
                    {({ field }) => (
                        <SelectField
                            fieldName = {prefix+"assetOwnership"}
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={assetOwnershipList}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"assetOwnership").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"assetOwnership") } </div> : "" }
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Make</label>
                <Field name= { prefix+"make"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"make"} 
                            fieldValue = {field.value !== undefined ? field.value : 0 } 
                            placeholder = "Make" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"make").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"make") } </div> : "" }
            </div>                        
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Model</label>
                <Field name= { prefix+"model"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"model"} 
                            fieldValue = {field.value !== undefined ? field.value : 0 } 
                            placeholder = "Model" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"model").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"model") } </div> : "" }
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Year</label>
                <Field name= { prefix+"year"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"year"} 
                            fieldValue = {field.value !== undefined ? field.value : "" } 
                            placeholder = "Year" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"year").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"year") } </div> : "" }
            </div>  
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Vin | Rego | Engine</label>
                <Field name= { prefix+"vin"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"vin"} 
                            fieldValue = {field.value !== undefined ? field.value : "" } 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"vin").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"vin") } </div> : "" }
            </div>  

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">State</label>
                <Field name=  {prefix+"state"}>
                    {({ field }) => (
                        <SelectField
                            fieldName = {prefix+"state"}
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={states}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"state").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"state") } </div> : "" }
            </div>

        </>
    )
}

export default VehicleAsset;