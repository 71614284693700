

const Error404 = ({message}) => {

    const sampleMessage= "The URL is expired please contact the finance broker for further help. ";
    return(
        <>
         <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
            <div style={{display:"flex"}}>
                    <div>{sampleMessage}</div>
            </div>
                <img src="/media/illustrations/dozzy-1/18-dark.png" alt="" className="h-lg-450px" />
            </div>
        </>
    )
}

export default Error404;