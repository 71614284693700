
import { useEffect, useState } from 'react';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, CustomButton, SelectField, InputField, IconButton } from '../../../../../components/HtmlComponents';
import Constants from '../../../../../components/global/Constants';
import StandardDebt from './StandardDebt';
import CreditCard from './CreditCard';
import BuyNowPayLater from './BuyNowPayLater';
import Overdraft from './Overdraft';
import OtherDebt from './OtherDebt';
import LandDebt from './LandDebt';


const SingleDebt = ({ index, debtType, debtsTypeList, frequencyList, setFieldValue, deleteDebt, errors }) => {
    const [selectedDebtType, setSelectedDebtType] = useState(debtType);

    //HANDLER TO UPDATE THE SELECTED DEBT TYPE - EXECUTES AS A CALLBACK FROM SELECT FIELD
    const decideDetailedBlockOnChange = (fieldName, value, setFieldValue) => { setSelectedDebtType(value); }

    useEffect(() => {
        setSelectedDebtType(debtType);
    }, [debtType])
    return (
        <>
            <div className="row mb-5" style={{ borderBottom: "1px dotted #edebeb" }}>
                <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container" >
                    <label className="fs-8 fw-bold mb-2">Liability Type <IconButton buttonOnClick={() => { deleteDebt(index) }} iconType="-" buttonStyle={{ border: "0px", backgroundColor: "transparent" }} iconColor="orange" /></label>
                    <Field name={"debts[" + index + "].debtType"}>
                        {({ field }) => (
                            <SelectField
                                fieldName={"debts[" + index + "].debtType"}
                                defaultSelectedOption={{ value: field.value !== undefined ? field.value : "", label: "" }}
                                options={debtsTypeList}
                                setFieldValue={setFieldValue}
                                callback={decideDetailedBlockOnChange}
                            />
                        )}
                    </Field>
                </div>

                {
                    ["personal", "car", "motorBike", "boat", "caravan", "jetSki", "truck", "investmentProperties", "hirePurchase"].includes(selectedDebtType) ?
                        <StandardDebt
                            prefix={"debts[" + index + "]."}
                            frequencyList={frequencyList}
                            setFieldValue={setFieldValue}
                            errors={errors}
                        /> : ""
                }
                {
                    ["land", "house", "mortgage"].includes(selectedDebtType) ?
                        <LandDebt
                            prefix={"debts[" + index + "]."}
                            frequencyList={frequencyList}
                            setFieldValue={setFieldValue}
                            errors={errors}
                        /> : ""
                }
                {
                    ["creditCard", "storeCard", "chargeCard"].includes(selectedDebtType) ?
                        <CreditCard
                            prefix={"debts[" + index + "]."}
                            frequencyList={frequencyList}
                            setFieldValue={setFieldValue}
                            errors={errors}
                        /> : ""
                }
                {
                    ["buyNowPayLater"].includes(selectedDebtType) ?
                        <BuyNowPayLater
                            prefix={"debts[" + index + "]."}
                            frequencyList={frequencyList}
                            setFieldValue={setFieldValue}
                            errors={errors}
                        /> : ""

                }
                {
                    ["overdraft"].includes(selectedDebtType) ?
                        <Overdraft
                            prefix={"debts[" + index + "]."}
                            frequencyList={frequencyList}
                            setFieldValue={setFieldValue}
                            errors={errors}
                        /> : ""

                }
                {
                    ["other"].includes(selectedDebtType) ?
                        <OtherDebt
                            prefix={"debts[" + index + "]."}
                            frequencyList={frequencyList}
                            setFieldValue={setFieldValue}
                            errors={errors}
                        /> : ""
                }

            </div>

        </>
    )

}

/**
 * 
 *         switch(true){
            //LAND DEBT
            case ["land" , "house" , "mortgage"].includes(debtType):
                 debtObj = new LandDebt();
                 await debtObj.createLandDebt(singleDebt);
                break;
            
        }

 */



export default SingleDebt; 