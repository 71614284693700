

const CongratulationMessageOnSubmission = () => {
    return (
        <div className="card mb-5 mt-5 mb-xxl-8" key="expensesBlock">
            <div className="card-body card-body pt-6 pb-6">
                <div className=" ">
                    <h3 className="pb-0 mb-0">Congratulations! Your application has been submitted!</h3>
                    <p className="pb-0 mt-3 mb-0"> </p>
                </div>              
            </div>
        </div>
    )
}

export default CongratulationMessageOnSubmission;