
const InputGroup = ({fieldName, symbolType, symbol, symbolPosition, placeholder, fieldValue , listName, setFieldValue}) => {


    const symbolList = {"dollar" : "$" , "at" : "@"};

    return (
        <>
            <div className="input-group">
                {symbolPosition === "left" ? <span className="input-group-text" style = {{ height: "35px"}} >{ symbolType !== "custom" ? symbolList[symbol]: symbol }</span> : ""}
                
                <input 
                type="text" 
                className="form-control"
                style = {{ height: "35px" , fontWeight: "400"}}  
                placeholder= {placeholder} 
                aria-label={placeholder}  
                value= {fieldValue}
                list = {listName}
                onChange={ (e) => {setFieldValue(fieldName,  e.target.value) } }                
                />
                {symbolPosition === "right" ? <span className="input-group-text" style = {{ height: "35px"}} >{ symbolType !== "custom" ? symbolList[symbol]: symbol }</span> : ""}

            </div>
        </>
    )
}

export default InputGroup; 