

import * as Yup from 'yup';

// Custom method to check the total duration of addresses
Yup.addMethod(Yup.array, 'checkTotalDuration', function (currentDurationRef, minTotalDuration) {
  return this.test('check-total-duration', `The combined duration of addresses must be at least ${minTotalDuration} months`, function (value) {
    const { path, createError, parent } = this;

    // Resolve the current duration reference
    const currentDuration = this.resolve(currentDurationRef);


    console.log('currentDuration   ----  line 10', currentDuration);
    // Calculate the total duration
    let totalDuration = currentDuration;
    value.forEach(prev => {
      if (prev && prev.duration && !isNaN(prev.duration) && prev.duration > 0 && !isNaN(totalDuration)) {
        totalDuration = parseInt(totalDuration) + parseInt(prev.duration);
      }
    });

    // Check if the total duration is less than the required minimum duration
    if (totalDuration < minTotalDuration) {
      return createError({ path, message: 'You must provide additional previous addresses to meet the minimum duration requirement.' });
    }
    return true;
  });
});



//RENT FIELDS SCHEMA
const rentFields = Yup.object().shape({
  rent: Yup.object().shape({
    totalIndividualsOnLease: Yup.string().required("Required"), // Yup.number().required('Total individuals on lease is required').min(1, 'Must be at least 1'),
    totalMonthlyRent: Yup.number().required('Total monthly rent is required').min(1, 'Must be at least 0'),
    applicantShareOfMonthlyRent: Yup.number().required('Applicant share of monthly rent is required').min(1, 'Must be at least 0'),
    frequency: Yup.string().required('Frequency is required')
  })
});


//RESIDENTIAL ADDRESS FIELDS SCHEMA
/*/*const addressSchema = Yup.object().shape({
  address: Yup.object().shape({
    fullAddress: Yup.string().required('Address is required'),
  }),
  duration: Yup.number().required('Duration is required').min(1, 'Duration must be more than 0'),
  //status: Yup.string().required('Residential status is required')
  status: Yup.string().required('Residential status is required').oneOf(['rent', 'rentingAgent', 'own', 'mortgage', 'other'], 'Invalid status')
    .when('residentialStatus', {
      is: (status) => ['rent', 'rentingAgent'].includes(status),
      then: rentFields,
    })
});
*/

const addressAndRentSchema = Yup.object().shape({
  address: Yup.object().shape({
    fullAddress: Yup.string().required('Address is required'),
  }),
  duration: Yup.number().required('Duration is required').min(1, 'Duration must be more than 0'),
  status: Yup.string().required('Residential status is required')
    .oneOf(['rent', 'rentingAgent', 'own', 'mortgage', 'other'], 'Invalid status'),
}).when('status', {
  is: value => ['rent', 'rentingAgent'].includes(value),
  then: rentFields,
});


/*************************************** START -*********************************** */
const addressSchema = Yup.object().shape({
  fullAddress: Yup.string().required('Full address is required'),
  unitNumber: Yup.string(),
  streetNumber: Yup.string().required('Street number is required'),
  streetTypeLongName: Yup.string(),
  streetTypeShortName: Yup.string(),
  streetName: Yup.string().required('Street name is required'),
  postCode: Yup.string().required('Postcode is required'),
  state: Yup.string().required('State is required'),
  suburb: Yup.string().required('Suburb is required')
});

// Define the schema for landlord information
const landlordSchema = Yup.object().shape({
  name: Yup.string().required('Landlord name is required')
});

// Define the schema for rent information
const rentSchema = Yup.object().shape({
  totalIndividualsOnLease: Yup.number().required('Total individuals on lease is required').min(1, 'At least one individual is required on lease'),
  frequency: Yup.string().required('Rent payment frequency is required'),
  totalMonthlyRent: Yup.number().required('Total monthly rent is required').min(0, 'Total monthly rent must be more than 0'),
  applicantShareOfMonthlyRent: Yup.number().required('Applicant share of monthly rent is required').min(0, 'Applicant share of monthly rent must be more than 0')
});

// Define the schema for current residential history
const currentResidentialHistorySchema = Yup.object().shape({
  address: addressSchema,
  duration: Yup.number().required('Duration at current address is required').min(1, 'Duration must be more than 0'),
  status: Yup.string().required('Current residential status is required'),
  landlord: Yup.object().when('status', {
    is: (status) => ['rentingPrivate', 'rentingAgent'].includes(status),
    then: landlordSchema,
    otherwise: Yup.object().notRequired()
  }),
  rent:  Yup.object().when('status', {
    is: (status) => ['rentingPrivate', 'rentingAgent'].includes(status),
    then: rentSchema,
    otherwise: Yup.object().notRequired()
  })
});

// Define the schema for previous residential history
const previousResidentialHistorySchema = Yup.object().shape({
  duration: Yup.number().required('Duration at previous address is required').min(1, 'Duration must be more than 0')
});

// Define the schema for residential history
const residentialHistorySchema = Yup.object().shape({
  current: currentResidentialHistorySchema,
  previous: Yup.array().of(previousResidentialHistorySchema)
});

/*************************************** END -*********************************** */

const PersonalDetailValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  firstName: Yup.string().required('First name is required'),
  middleName: Yup.string().nullable(),
  surname: Yup.string().required('Surname is required'),
  preferredName: Yup.string().required('Preferred name is required'),
  gender: Yup.string().required('Please select gender'),
  dob: Yup.string().required('Date of birth is required'),
  relationshipStatus: Yup.string().required('Relationship status is required'),
  residencyStatus: Yup.string().required('Residency status is required'),
  totalDependants: Yup.number().required('Total dependants is required').min(0, 'Total dependants must be 0 or more'),
  dependantsAge: Yup.array().of(Yup.number().min(1, 'Dependant age must be 1 or more. \n   ').required('Dependant age is required. \n ')),
  license: Yup.object().shape({
    unlicensed: Yup.boolean().required('License status is required'),
    number: Yup.string().when('unlicensed', {
      is: false,
      then: Yup.string().required('License number is required')
    }),
    type: Yup.string().when('unlicensed', {
      is: false,
      then: Yup.string().required('License type is required')
    }),
    state: Yup.string().when('unlicensed', {
      is: false,
      then: Yup.string().required('License state is required')
    }),
    cardNumber: Yup.string().when('unlicensed', {
      is: false,
      then: Yup.string().required('License card number is required')
    }),
    expiryDate: Yup.string().when('unlicensed', {
      is: false,
      then: Yup.string().required('License expiry date is required')
    })
  }),
  medicare: Yup.object().shape({
    number: Yup.string().nullable(),
    expiryDate: Yup.string().nullable(),
    ref: Yup.string().nullable()
  }),
  residentialHistory: residentialHistorySchema,
  /*residentialHistory: Yup.object().shape({
    current: addressAndRentSchema.shape({
      duration: Yup.number().required('Duration is required').min(1, 'Duration must be more than 0'),
    }),
    previous: Yup.array().when('current.duration', {
      is: (duration) => duration < 36,
      then: Yup.array().of(addressAndRentSchema).checkTotalDuration(Yup.ref('current.duration'), 36)
    })
  }), */

  /*/*residentialHistory: Yup.object().shape({
    current: Yup.object().shape({
      address: Yup.object().shape({
        fullAddress: Yup.string().required('Current address is required'),
      }),
      duration: Yup.number().required('Duration is required').min(0, 'Duration must be more than 0'),
      status: Yup.string().required('Residential status is required'),
    }),
    previous: Yup.array().when('current.duration', {
      is: (duration) => duration < 36,
      then: Yup.array().of(Yup.object().shape({
        address: Yup.object().shape({
          fullAddress: Yup.string().required('Previous address is required'), 
        }),
        duration: Yup.number().required('Duration is required').min(1, 'Duration must be 0 or more'),
        status: Yup.string().required('Residential status is required'), 
      })).checkTotalDuration(Yup.ref('current.duration'), 36)
    })
  }),*/
  email: Yup.string().email('Invalid email').required('Email is required'),
  mobileNumber: Yup.string().required('Mobile number is required'),
  homeNumber: Yup.string().nullable(),
  employerNumber: Yup.string().nullable(),
  //  bestContactMethod: Yup.string().required('Best contact method is required'),
  notes: Yup.string().nullable()
});


export default PersonalDetailValidationSchema;