import { useEffect, useRef, useState } from "react"
import ResidentialAddressDetail from "./ResidentialAddressDetail"
import { FieldArray } from "formik";

const ResidentialHistory = ({ prefix, errors, setFieldValue, residentialStatusList, residentialHistory, values }) => {
    const addAddressButtonRef = useRef();
    const deleteAddressButtonRef = useRef();
    const [currentPreResidentialStatus, setCurrentPreResidentialStatus] = useState(null);

    const addressBlockSchema = {
        "address": {
            "fullAddress": "",
            "unitNumber": "",
            "streetNumber": "",
            "streetTypeLongName": "",
            "streetTypeShortName": "",
            "streetName": "",
            "postCode": "",
            "state": "",
            "suburb": ""
        },
        "duration": 0,
        "status": ""
    }

    const addWaitBeforeNextProcess = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        var preResidentialStatus = residentialHistory["current"] !== undefined && residentialHistory["current"]["status"] !== undefined ? residentialHistory["current"]["status"] : "";
        setCurrentPreResidentialStatus(preResidentialStatus);
        // activatePreviousResidentialAddressBlocks();
    }, [residentialHistory])

    //DECIDES IF NEED TO ADD ANOTHER RESIDENTIAL BLOCK - DECISION BASED ON THE NUMBER OF MONTHS
    const totalLivingDurationCallback = async(name, value) => {
        await addWaitBeforeNextProcess(1000);

        //CHECK IF PREVIOUS ADDRESS ARRAY ALREADY HAS 2 PREVIOUS ADDRESS THEN DO NOTHEING OTHERIWSE ADD A NEW PREVIOUS ADDRESS
        var countPreviousAddresses = 0;
        if (Array.isArray(values.residentialHistory.previous) && values.residentialHistory.previous.length > 0) { countPreviousAddresses = values.residentialHistory.previous.length; }

        //get duration of each available current and previous address
        var currentDuration = values.residentialHistory.current.duration;
        var previousDuration1 = Array.isArray(values.residentialHistory.previous) && values.residentialHistory.previous[0] !== undefined ? values.residentialHistory.previous[0].duration : -1;
        var previousDuration2 = Array.isArray(values.residentialHistory.previous) && values.residentialHistory.previous[1] !== undefined ? values.residentialHistory.previous[1].duration : -1;

        switch (name) {
            case "residentialHistory.current.duration":
                currentDuration = value;
                break;
            case "residentialHistory.previous[0].duration":
                previousDuration1 = value;
                break;
            case "residentialHistory.previous[1].duration":
                previousDuration2 = value;
                break;
        }

        //ONLY CURRENT ADDRESS PROVIDED AND DURATION IS LESS THAN 36 , AND PREVIOUS ADDRESS IS NOT PRESENT AT ALL
        if (currentDuration > 0 && currentDuration < 36 && countPreviousAddresses == 0) {
            addPreviousAddress();
        }

        //current address less than 36 and previous address 1 is added  and duration is provided for previous address 1 but sum of current and previous address less than 36 so add 2nd previous address
        if (currentDuration < 36 && countPreviousAddresses == 1 && previousDuration1 > 0 && (currentDuration + previousDuration1) < 36) {
            addPreviousAddress();
        }


        //IF CURRENT ADDRESS ITSELF IS EQUAL OR ABOVE THAN 36 THEN REMOVE ALL PREVIOUS ADDRESS
        if (currentDuration >= 36) { deletePreviousAddress(); deletePreviousAddress(); }

        //IF CURRENT ADDRESS ITSELD OR SUM OF CURRENT + PREVIOUS 1 IS EQUAL OR ABOVE THAN 36 THEN REMOVE THE PREVIOUS ADDRESS 2
        if (currentDuration + previousDuration1 >= 36 && countPreviousAddresses == 2) { deletePreviousAddress(); }
             
    }
 
    const addPreviousAddress = () => {
        // Programmatically trigger button click
        if (addAddressButtonRef.current) {  addAddressButtonRef.current.click(); }
    }
    
    const deletePreviousAddress = () => {
        // Programmatically trigger button click
        if (deleteAddressButtonRef.current) {  deleteAddressButtonRef.current.click(); }
    }


    //REMOVE PREVIOUS ADDRESS BLOCK


    return (
        <>

            <ResidentialAddressDetail
                errors={errors}
                prefix="residentialHistory.current."
                setFieldValue={setFieldValue}
                residentialStatusList={residentialStatusList}
                totalLivingDurationCallback={totalLivingDurationCallback}
                preResidentialStatus={currentPreResidentialStatus}
            />
              
            {/* FORMIK ARRAY TO DEAL WITH PREVIOUS ADDRESS */}

            {
                Array.isArray(values.residentialHistory.previous) && values.residentialHistory.previous.length > 0 ?
                    values.residentialHistory.previous.map((address, index) => (
                        <ResidentialAddressDetail
                            key={index}
                            errors={errors}
                            prefix={`residentialHistory.previous[${index}].`}
                            setFieldValue={setFieldValue}
                            residentialStatusList={residentialStatusList}
                            totalLivingDurationCallback={totalLivingDurationCallback}
                            preResidentialStatus={""}
                        />
                    ))
                    : ""
            }

            <FieldArray name="residentialHistory.previous">
                {({ remove, push }) => (
                    <div>

                        <button style={{display:"none"}} ref={addAddressButtonRef} onClick={() => push(addressBlockSchema)}>Add Address</button>
                        <button style={{display:"none"}} ref={deleteAddressButtonRef} onClick={() => {
                            let previousAddressLength = values.residentialHistory.previous.length;
                            if(previousAddressLength > 0){ remove(previousAddressLength - 1);}
                        }}></button>

                    </div>

                )}
            </FieldArray>


            {
                /*residentialAddressList[1].active ?
                    <ResidentialAddressDetail
                        errors={errors}
                        prefix="residentialHistory.previous[0]."
                        setFieldValue={setFieldValue}
                        residentialStatusList={residentialStatusList}
                        totalLivingDurationCallback={totalLivingDurationCallback}
                        preResidentialStatus=""
                    />
                    : ""*/
            }
            {
                /*residentialAddressList[2].active ?
                    <ResidentialAddressDetail
                        errors={errors}
                        prefix="residentialHistory.previous[1]."
                        setFieldValue={setFieldValue}
                        residentialStatusList={residentialStatusList}
                        totalLivingDurationCallback={totalLivingDurationCallback}
                        preResidentialStatus=""
                    />
                    : ""*/
            }
        </>
    )

}

export default ResidentialHistory;