import * as Yup from 'yup';

  // Define separate schemas for each income type
  const categorySchemas = {
    standard: Yup.object().shape({
        assetType: Yup.string().required('Type is required'),
        assetValue: Yup.number().required('Required').min(1, 'Must be more than 0'),
        assetOwnership: Yup.string().required('Required'),
        make: Yup.string().required('Required'),
        model: Yup.string().required('Required'),
        year: Yup.number().required('Required'),
        //vin: Yup.string().required('Required'),
        //state: Yup.string().required('Required'),
    }),
    category2: Yup.object().shape({
        assetType: Yup.string().required('Type is required'),
        assetValue: Yup.number().required('Required').min(1, 'Must be more than 0'),
        assetOwnership: Yup.string().required('Required')
    }),
    category3: Yup.object().shape({
        assetType: Yup.string().required('Type is required'),
        assetValue: Yup.number().required('Required').min(1, 'Must be more than 0'),
        assetOwnership: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
    }),
  };
    
  const assetsEntrySchema = (values) => {
    let category = '';
    switch(true){
        case [ "car", "caravan" , "motorBike" , "boat" , "jetSki" , "truck"].includes(values.assetType):
            category = "standard";    
        break;
        case ["houseContents" , "house" , "land" , "investmentProperties" , "superannuation"].includes(values.assetType):
            category = "category2";
            break;        
        case ["cashInBank" , "other"].includes(values.assetType):
            category = "category3";
            break;    
         default:
            category = values.assetType;
            break;
    }
    const schema = categorySchemas[category] || Yup.object(); // Default to an empty schema if income type not found
    return schema;
  };
  

// Define the validation schema for the income object
const AssetsValidationSchema = Yup.object().shape({
    assets:  Yup.array().of(Yup.lazy(assetsEntrySchema))
});

export default AssetsValidationSchema;
