
import * as Yup from 'yup';




const tradeInvehicleSchema = Yup.object().shape({
  make: Yup.string().required('Make is required'),
  model: Yup.string().required('Maodel is required'),
  year: Yup.number().min(1995, 'Year must be above 1995').required('Year is required'),
  bodyType: Yup.string().required('Body type is required'),
  transmission: Yup.string().required('Transmission is required'),
});


const financierSchema = Yup.object().shape({
  name: Yup.string().required('Financier name is required'),
  amountOutstanding: Yup.string()
  .matches(/^\d+(\.\d{1,2})?$/, 'Please enter a valid amount.')
  .test('is-decimal', 'Please enter a valid amount.', value => { return !value || /^\d+(\.\d{1,2})?$/.test(value); })
  .test('min-amount', 'The value must be greater than $0.', value => { return parseFloat(value) > 0; }),
});

const financeSchema = Yup.object().shape({
  financed: Yup.boolean().required(),
  financier: Yup.object().when("financed", {
    is: true,
    then: financierSchema,
    otherwise: Yup.object().shape({})
  }) 
});

/*const tradeInSchema = Yup.object().shape({
  isTradingIn: Yup.boolean().required(),
  amount: Yup.number().min(0, 'Amount must be at least 0').required('Amount is required'),
  vehicle: vehicleSchema.required('Vehicle is required'),
  finance: financeSchema.required('Finance is required')
});*/

const tradeInSchema = Yup.object().shape({
  isTradingIn: Yup.boolean(),
  amount: Yup.number().when('isTradingIn', {
    is: true,
    then: Yup.number().min(1000, 'Amount must be at least 1000').required('Amount is required'),
    otherwise: Yup.number(),
  }),
  vehicle: Yup.object().when('isTradingIn', {
    is: true,
    then: tradeInvehicleSchema,
    otherwise: Yup.object().shape({}),
  }),
  finance: Yup.object().when('isTradingIn', {
    is: true,
    then: financeSchema,
    otherwise: Yup.object().shape({}),
  }),
});




const financialSchema = Yup.object().shape({
  assetPrice: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, 'Please enter a valid amount.')
    .test('is-decimal', 'Please enter a valid amount.', value => { return !value || /^\d+(\.\d{1,2})?$/.test(value); })
    .test('min-amount', 'Asset price must be at least $5000.', value => { return parseFloat(value) >= 5000; }),

  cashDeposit: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, 'Please enter a valid amount.')
    .test('is-decimal', 'Please enter a valid amount.', value => { return !value || /^\d+(\.\d{1,2})?$/.test(value); })
    .test('min-amount', 'Please enter a valid amount.', value => { return parseFloat(value) >= 0; }),
  tradeIn: tradeInSchema,
});


const motorVehicleSchema = Yup.object().shape({
  condition: Yup.string().required('Condition is required'),
  make: Yup.string().required('Make is required').max(20, 'max 20 characters allowed'),
  year: Yup.number().min(1900, 'Required').required('Year is required'),
  model: Yup.string().required('Model is required').max(20, 'max 20 characters allowed'),
  bodyType: Yup.string().required('Body type is required'),
  transmission: Yup.string().required('Transmission is required'),
  /*buildDate: Yup.string().required('Build date is required'),
  complianceDate: Yup.string().required('Compliance date is required'),
  totalKilometer: Yup.string().required('Total kilometer is required'),
  registrationNumber: Yup.string().required('Registration number is required'),
  vin: Yup.string().required('VIN is required'),
  nvic: Yup.string().required('NVIC is required'),
  engineNumber: Yup.string().required('Engine number is required'),
  color: Yup.string().required('Color is required'),
  estimatedDeliveryDate: Yup.string().required('Estimated delivery date is required'),*/
  description: Yup.string().required('Description is required').max(150, 'max 150 characters allowed')
});

const loanSchema = Yup.object().shape({
  purpose: Yup.string().required('Purpose is required'),
  type: Yup.string().required('Type is required'),
  loanTerm: Yup.number().min(12, 'Loan term must be greater than 12').required('Loan term is required'),
  balloon: Yup.object().shape({
    balloonAmount: Yup.number().required('Amount is required'),
    balloonPercentage: Yup.string()
    .matches(/^\d+(\.\d{1,2})?$/, 'Please enter a valid amount.')
    .test('is-decimal', 'The balloon percentage should be between 0% and 50%..', value => { return !value || /^\d+(\.\d{1,2})?$/.test(value); })
    .test('min-amount', 'The balloon percentage should be between 0% and 50%.', value => { return parseFloat(value) >= 0; })
    .test('max-amount', 'The balloon percentage should be between 0% and 50%.', value => { return parseFloat(value) <= 50; }),
  }),
});

const buyingItemSchema = Yup.object().shape({
  motorVehicles: Yup.array().of(motorVehicleSchema).required('Motor vehicles are required')
});

const QuoteValidationSchema = Yup.object().shape({
  category: Yup.string().required('Required'),
  loan: loanSchema,
  financial: financialSchema.required('Financial information is required'),
  buyingItem: buyingItemSchema.required('Buying item is required')
});

export default QuoteValidationSchema;