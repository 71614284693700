import { useEffect, useState } from "react";
import { InputField } from '../../../../../components/HtmlComponents';

const ResidentialStayDuration = ({ fieldName, fieldValue, setFieldValue , totalLivingDurationCallback}) => {

    const [months, setMonths] = useState(0);
    const [years, setYears] = useState(0);
    const [totalMonths , setTotalMonths] = useState(0);

    useEffect( () =>{
        //console.log(`FIELD VALUE FROM ResidentialStayDuration USE EFFECT::::: ${fieldName} :::  `, fieldValue);
//        var tempTotalMonths =  !isNaN(parseInt(fieldValue)) ? parseInt(fieldValue) : 0;
        //setYears( parseInt(tempTotalMonths/12) );
  //      setMonths( parseInt(tempTotalMonths%12) );
    },[totalMonths])

    
    useEffect( () => {
        //UPDATE TOTAL MONTHS STATE IF FIELD VALUE IS DIFFERENT
        if(fieldValue !== totalMonths){
            setTotalMonths(parseInt(fieldValue) || 0);
        }
    },[fieldValue])

    useEffect(() => {
        setFieldValue(fieldName, (parseInt(months) || 0) );
        totalLivingDurationCallback(fieldName, (parseInt(months) || 0));
        setTotalMonths((parseInt(months) || 0) )
    }, [months, years])

    const handleChanges = (name, value) => {
        if (name == "months") { setMonths(  !isNaN(parseInt(value)) ? parseInt(value) : '' ) }
        if (name == "years") { setYears( !isNaN(parseInt(value)) ? parseInt(value) : '' ); }
    }

    return (
        <>
            <div className="row">
                {/*<div className="col-sm-4 col-md-6 fv-row fv-plugins-icon-container">
                    <InputField
                        placeholder="Years"
                        fieldName="years"
                        fieldValue={years}
                        setFieldValue={handleChanges}
                    />
                </div>*/}
                <div className="col-sm-12 fv-row fv-plugins-icon-container">
                    <InputField
                        placeholder="Months"
                        fieldName="months"
                        fieldValue={months}
                        setFieldValue={handleChanges}
                    />
                </div>
            </div>
        </>
    )
}

export default ResidentialStayDuration;