import { Field } from 'formik';
import { InputField, SelectField , FloatField, NumberField } from '../../../../../components/HtmlComponents';
import { useEffect } from 'react';
import {readNestedErrorByPath } from '../../../../../helpers';

const frequencyList = [
    { value: "weekly", label: "Weekly" },
    { value: "fortnightly", label: "Fortnightly" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "yearly", label: "Yearly" }
];


const RentDetail = ({ prefix, setFieldValue, errors}) => {

    useEffect(() => {
        //setFieldValue(prefix + "totalIndividualsOnLease", "");
        //setFieldValue(prefix + "frequency", "");
    }, [])
    return (
        <>
            <div className="row" >
                <div className="col-sm-4 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Number of Individuals on Lease</label>
                    <Field name={prefix + "totalIndividualsOnLease"} >
                        {({ field }) => (
                            <NumberField
                                fieldName={prefix + "totalIndividualsOnLease"}
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                    { readNestedErrorByPath(errors, prefix+"totalIndividualsOnLease").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"totalIndividualsOnLease") } </div> : "" }
                </div>

                <div className="col-sm-4 col-md-3 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Total Monthly Rent Amount</label>
                    <Field name={prefix + "totalMonthlyRent"} >
                        {({ field }) => (
                            <FloatField
                                fieldName={prefix + "totalMonthlyRent"}
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                    { readNestedErrorByPath(errors, prefix+"totalMonthlyRent").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"totalMonthlyRent") } </div> : "" }
                </div>

                <div className="col-sm-4 col-md-4 mb-5 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Your share of Total Rent Amount*</label>
                    <Field name={prefix + "applicantShareOfMonthlyRent"} >
                        {({ field }) => (
                            <FloatField
                                fieldName={prefix + "applicantShareOfMonthlyRent"}
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                    { readNestedErrorByPath(errors, prefix+"applicantShareOfMonthlyRent").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"applicantShareOfMonthlyRent") } </div> : "" }
                </div>

                <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                    <label className="  fs-8 fw-bold mb-2">Frequency</label>
                    <Field name={prefix + "frequency"}>
                        {({ field }) => (
                            <SelectField
                                fieldName={prefix + "frequency"}
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={frequencyList}
                                setFieldValue={setFieldValue}
                            />                            
                        )}
                    </Field>
                    { readNestedErrorByPath(errors, prefix+"frequency").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"frequency") } </div> : "" }
                </div>

            </div>

        </>
    )

}

export default RentDetail;