import { FormSubmissionButton, CustomButton, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import {readNestedErrorByPath} from '../../../../../helpers';
import { useEffect } from 'react';


const OtherAsset = ({ prefix, errors,  setFieldValue, assetOwnershipList, selectedAssetType }) => {

    useEffect(() => {

       //setFieldValue(prefix+"assetValue", 0);
       //setFieldValue(prefix+"assetOwnership", ["cashInBank", "superannuation", "houseContents"].includes(selectedAssetType) ? "ownOutright" : "");
       //setFieldValue(prefix+"description", ""); 

    }, [])

    return (
        <>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Asset Value</label>
                <Field name={prefix + "assetValue"}>
                    {({ field }) => (
                        <FloatField
                            fieldName={prefix + "assetValue"}
                            fieldValue={field.value !== undefined ? field.value : 0}
                            placeholder="Asset Value"
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"assetValue").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"assetValue") } </div> : "" }
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Asset Ownership</label>
                <Field name={prefix + "assetOwnership"}>
                    {({ field }) => (
                        <SelectField
                            fieldName={prefix + "assetOwnership"}
                            defaultSelectedOption={{ value: ["cashInBank", "superannuation", "houseContents"].includes(selectedAssetType) ? "ownOutright" : field.value, label: "" }}
                            options={assetOwnershipList}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"assetOwnership").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"assetOwnership") } </div> : "" }
            </div>

            {
                ["cashInBank", "other"].includes(selectedAssetType) ?

                    <div className="col-sm-3 col-md-4 mb-5 fv-row fv-plugins-icon-container" >
                        <label className="  fs-8 fw-bold mb-2"> { selectedAssetType == "other" ? "Description" : "Bank Name"} </label>
                        <Field name={prefix + "description"}>
                            {({ field }) => (
                                <InputField
                                    fieldName={prefix + "description"}
                                    fieldValue = {field.value !== undefined ? field.value : ""}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        { readNestedErrorByPath(errors, prefix+"description").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"description") } </div> : "" }
                    </div>
                    : ""
            }

        </>
    )
}

export default OtherAsset;