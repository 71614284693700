import { SelectField, RadioGroup, InputGroup, InputField , FloatField} from '../../../../../components/HtmlComponents';
import { Field } from 'formik';
import { useState, useEffect } from 'react';
import Constants from '../../../../../components/global/Constants';
import { capitalizeFirst } from '../../../../../helpers';


const TradeIn = ({ errors, touched, setFieldValue, values }) => {

    const [isTradeIn, setIsTradeIn] = useState(false);
    const [bodyTypes, setBodyTypes] = useState([]);
    const [transmissions, setTransmissions] = useState([]);


    useEffect(() => {
        //BODY TYPES
        var bodyTypesOptions = [];
        Constants.BODY_TYPES.map(item => {
            bodyTypesOptions.push({ value: item, label: capitalizeFirst(item) })
        })
        setBodyTypes(bodyTypesOptions);

        //TRANSMISSION
        var transmissionOptions = [];
        Constants.TRANSMISSION.map(item => {
            transmissionOptions.push({ value: item, label: capitalizeFirst(item) })
        })
        setTransmissions(transmissionOptions);
    }, [])

    function ResetFinancedCHildValues (setFieldValue){
        setFieldValue("financial.tradeIn.finance.financier.name", "");
        setFieldValue("financial.tradeIn.finance.financier.amountOutstanding", 0);
    }

    function TradeItemFinancedBlock(errors) {

        return (
            <>
                <div className="col-md-4 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Financier</label>
                    <Field name="financial.tradeIn.finance.financier.name" >
                        {({ field }) => (
                            <InputField
                                fieldName="financial.tradeIn.finance.financier.name"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={30}
                            />
                        )}
                    </Field>
                    {errors.financial && errors.financial.tradeIn && errors.financial.tradeIn.finance  && errors.financial.tradeIn.finance.financier && errors.financial.tradeIn.finance.financier.name   ? <div  className="error"> {errors.financial.tradeIn.finance.financier.name} </div> : "" }                                                                                                    
                </div>
                <div className="col-md-4 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Amount Outstanding</label>
                    <Field name="financial.tradeIn.finance.financier.amountOutstanding" >
                        {({ field }) => (
                            <FloatField
                                fieldName="financial.tradeIn.finance.financier.amountOutstanding"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                    {errors.financial && errors.financial.tradeIn && errors.financial.tradeIn.finance  && errors.financial.tradeIn.finance.financier && errors.financial.tradeIn.finance.financier.amountOutstanding   ? <div  className="error"> {errors.financial.tradeIn.finance.financier.amountOutstanding} </div> : "" }                                                                                                                        
                </div>


            </>
        )
    }

    function TradeInBlock(errors) {
        return (
            <>
                <div className="row">
                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Amount</label>
                        <Field name="financial.tradeIn.amount" >
                            {({ field }) => (
                                <FloatField
                                    fieldName="financial.tradeIn.amount"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors !== undefined  && errors.financial !== undefined  && errors.financial.tradeIn !== undefined  && errors.financial.tradeIn.amount !== undefined ? <div  className="error"> {errors.financial.tradeIn.amount} </div> : "" }                                                                                                                      
                    </div>
                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Year</label>
                        <Field name="financial.tradeIn.vehicle.year" >
                            {({ field }) => (
                                <InputField
                                    fieldName="financial.tradeIn.vehicle.year"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors !== undefined  && errors.financial !== undefined  && errors.financial.tradeIn !== undefined && errors.financial.tradeIn.vehicle !== undefined && errors.financial.tradeIn.vehicle.year !== undefined ? <div  className="error"> {errors.financial.tradeIn.vehicle.year} </div> : "" }
                    </div>
                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Make</label>
                        <Field name="financial.tradeIn.vehicle.make" >
                            {({ field }) => (
                                <InputField
                                    fieldName="financial.tradeIn.vehicle.make"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                    charactersLimit={20}
                                />
                            )}
                        </Field>
                        {errors !== undefined  && errors.financial !== undefined  && errors.financial.tradeIn !== undefined && errors.financial.tradeIn.vehicle !== undefined && errors.financial.tradeIn.vehicle.make !== undefined ? <div  className="error"> {errors.financial.tradeIn.vehicle.make} </div> : "" }
                    </div>
                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Model</label>
                        <Field name="financial.tradeIn.vehicle.model" >
                            {({ field }) => (
                                <InputField
                                    fieldName="financial.tradeIn.vehicle.model"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                    charactersLimit={20}
                                />
                            )}
                        </Field>
                        {errors !== undefined  && errors.financial !== undefined  && errors.financial.tradeIn !== undefined && errors.financial.tradeIn.vehicle !== undefined && errors.financial.tradeIn.vehicle.model !== undefined ? <div  className="error"> {errors.financial.tradeIn.vehicle.model} </div> : "" }
                    </div>
                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Body Type</label>
                        <Field name="financial.tradeIn.vehicle.bodyType">
                            {({ field }) => (
                                <SelectField
                                    fieldName="financial.tradeIn.vehicle.bodyType"
                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                    options={bodyTypes}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors !== undefined  && errors.financial !== undefined  && errors.financial.tradeIn !== undefined && errors.financial.tradeIn.vehicle !== undefined && errors.financial.tradeIn.vehicle.bodyType !== undefined ? <div  className="error"> {errors.financial.tradeIn.vehicle.bodyType} </div> : "" }
                    </div>
                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Transmission</label>
                        <Field name="financial.tradeIn.vehicle.transmission">
                            {({ field }) => (
                                <SelectField
                                    fieldName="financial.tradeIn.vehicle.transmission"
                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                    options={transmissions}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Field>
                        {errors !== undefined  && errors.financial !== undefined  && errors.financial.tradeIn !== undefined && errors.financial.tradeIn.vehicle !== undefined && errors.financial.tradeIn.vehicle.transmission !== undefined ? <div  className="error"> {errors.financial.tradeIn.vehicle.transmission} </div> : "" }
                    </div>

                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Registration #</label>
                        <Field name="financial.tradeIn.vehicle.registrationNumber" >
                            {({ field }) => (
                                <InputField
                                    fieldName="financial.tradeIn.vehicle.registrationNumber"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                    charactersLimit={7}
                                />
                            )}
                        </Field>
                    </div>

                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">VIN</label>
                        <Field name="financial.tradeIn.vehicle.vin" >
                            {({ field }) => (
                                <InputField
                                    fieldName="financial.tradeIn.vehicle.vin"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                    charactersLimit={36}
                                />
                            )}
                        </Field>
                    </div>



                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Engine #</label>
                        <Field name="financial.tradeIn.vehicle.engineNumber" >
                            {({ field }) => (
                                <InputField
                                    fieldName="financial.tradeIn.vehicle.engineNumber"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                    charactersLimit={36}
                                />
                            )}
                        </Field>
                    </div>

                    <div className="col-md-3 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Color</label>
                        <Field name="financial.tradeIn.vehicle.color" >
                            {({ field }) => (
                                <InputField
                                    fieldName="financial.tradeIn.vehicle.color"
                                    fieldValue={field.value}
                                    setFieldValue={setFieldValue}
                                    charactersLimit={20}
                                />
                            )}
                        </Field>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-4 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                        <label className="required fs-8 fw-bold mb-2">Financed</label>
                        <Field name="financial.tradeIn.finance.financed" >
                            {({ field }) => (
                                <SelectField
                                    fieldName="financial.tradeIn.finance.financed"
                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                    setFieldValue={setFieldValue}
                                    callback = { (fieldName,  value, setFieldValue) => {
                                        if(value == true){ ResetFinancedCHildValues(setFieldValue); }
                                    }}
                                />
                            )}
                        </Field>
                    </div>

                    {values.financial.tradeIn.finance.financed == true ? TradeItemFinancedBlock(errors) : ""}
                </div>
            </>
        )
    }

    return (
        <>
            <div className="row mb-5">
                <div className="col-md-6 fv-row fv-plugins-icon-container">
                    <label className="fs-8 fw-bold mb-2" style={{ marginRight: "20px" }}>Trade In</label>
                    {/*<Field name="financial.tradeIn.isTradingIn" >
                            {({ field }) => (
                                <SelectField
                                    fieldName="financial.tradeIn.isTradingIn"
                                    defaultSelectedOption={{ value: field.value, label: '' }}
                                    options={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                    setFieldValue={setFieldValue}
                                    callback = { (fieldName,  value, setFieldValue) => {
                                        if(value == true){ ResetFinancedCHildValues(setFieldValue); }
                                    }}
                                />
                            )}
                        </Field>*/}
                        
                     <Field name="financial.tradeIn.isTradingIn">
                        {({ field }) => (
                            <RadioGroup
                                fieldName="financial.tradeIn.isTradingIn"
                                defaultSelectedOption={field.value}
                                options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                setFieldValue={setFieldValue}
                                layoutStyle="horizontal"
                            />
                        )}
                    </Field>  
                </div>
            </div>
            {values.financial.tradeIn.isTradingIn == true ? TradeInBlock(errors) : ""}

        </>
    )
}

export default TradeIn;