import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFile } from "../../api";
import { useToken, useEntityType } from '../../hooks/useAuth';
import Notifications from '../../components/global/Notifications';


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

/**
 * generalFiles  => if it is undefined then call methods from fileApi otherwisegeneralFileApi
 * 
 */
function UploadFile({ applicationId, applicantId, token }) {
    const [filesEntries, setFilesEntries] = useState([]);
    const fileLimit = 10;


    //UPDATE FILE ENTRY


    const uploadFileToServer = async (fileName, fileObj) => {
        const response = await uploadFile(token, applicationId, applicantId, fileObj);
        if (response["error"] !== undefined && response["error"] == 1) { Notifications("error", response["message"] || "Error occoured uploading this file."); }
        else {
            Notifications("success", "File uploaded successfully.");

            //fileObj is a from Date object so how to get the key value from this object "name"
            setFilesEntries(prev => [...prev, fileName])
        }
    }

    //USE CALLBACK 
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            const data = new FormData()
            data.append('file', file);
            const fileName = file.name;
            console.log("LINE 69 - FILE NAM E --- ", file.name);

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (event) => {
                uploadFileToServer(fileName, data);
            }
            reader.readAsDataURL(file);
        })

    }, [applicationId, applicantId, token])

    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        maxFiles: fileLimit,
        accept: {
            'image/*': ['.png', '.jpeg', '.jpg'],
            'application/pdf': []
        },
        onDrop
    });
    const style = useMemo(() => ({ ...baseStyle, ...(isFocused ? focusedStyle : {}), ...(isDragAccept ? acceptStyle : {}), ...(isDragReject ? rejectStyle : {}) }), [isFocused, isDragAccept, isDragReject]);


    const acceptedFilesListing = acceptedFiles.map(file => (
        <div key={file.path} className="uploadFileListing">
            {file.path} - {file.size / (1000 * 1000) < 1 ? (file.size / 1000) + "KB" : file.size / (1000 * 1000) + "MB"}
        </div>
    ));


    return (
        <>
            <div style={{ marginBottom: "20px" }}>
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <div className=" uploadFileContainer">
                        <div><i className="bi bi-file-earmark-arrow-up text-primary fs-3x"></i></div>
                        <div >
                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                            <span className="fs-7 fw-semibold text-gray-400">Upload up to {fileLimit} files synchronously</span>
                        </div>
                    </div>
                </div>
                <div>
                    {acceptedFilesListing}
                </div>
            </div>

            {/** LIST FILES AS BLOCK */}
            {
                Array.isArray(filesEntries) && filesEntries.length > 0 && filesEntries.map((file, index) => {
                    return (
                        <div key={index} className="row col-sm-12 mb-2" style={{  backgroundColor: "#efeded" , padding: "2px"}}>
                            <p className="mb-0 mt-2">{file}</p>
                        </div>
                    )
                })
            }
        </>
    );
}

export default UploadFile;