import * as Yup from 'yup';

// Define the schema for an individual income entry
/*const incomeEntrySchema = Yup.object().shape({
  incomeType: Yup.string().required('Income type is required'),
  duration: Yup.number().required('Duration is required').min(0, 'Duration must be more than 0'),
  grossIncome: Yup.number().required('Gross income is required').min(0, 'Gross income must be more than 0'),
  netIncome: Yup.number().required('Net income is required').min(0, 'Net income must be more than 0'),
  frequency: Yup.string().required('Frequency is required'),
  employer: Yup.object().shape({
    name: Yup.string().required('Employer name is required'),
    contactNumber: Yup.string().required('Contact number is required'),
    address: Yup.object().shape({
      fullAddress: Yup.string().required('Address is required'),
    })
  }),
  occupation: Yup.string().required('Occupation is required')
});*/

  // Define separate schemas for each income type
  const incomeCategorySchemas = {
    standard: Yup.object().shape({
        incomeType: Yup.string().required('Income type is required'),
        duration: Yup.number().required('Duration is required').min(1, 'Must be more than 0'),
        grossIncome: Yup.number().required('Gross income is required').min(0, 'Gross income must be more than 0'),
        netIncome: Yup.number().required('Net income is required').min(0, 'Net income must be more than 0'),
        frequencey: Yup.string().required('Frequency is required'),
        employer: Yup.object().shape({
          name: Yup.string().required('Employer name is required'),
          contactNumber: Yup.string().required('Contact number is required'),
          /*addressUnknown: Yup.boolean().required(),
          address: Yup.object().shape({
            fullAddress: Yup.string().when('addressUnknown', {
              is: true,
              then: Yup.string().notRequired(),
              otherwise: Yup.string().required('Address is required')
            })
          })*/
        }),
        occupation: Yup.string().required('Occupation is required')
    }),
    investment: Yup.object().shape({
        incomeType: Yup.string().required('Income type is required'),
        duration: Yup.number().required('Required').min(1, 'Must be more than 0'),
        grossIncome: Yup.number().required('Gross income is required').min(0, 'Gross income must be more than 0'),
        netIncome: Yup.number().required('Net income is required').min(0, 'Net income must be more than 0'),
        frequency: Yup.string().required('Frequency is required'),
        agentName: Yup.string().required('Agent name is required'),
        agentContactNumber: Yup.number().required('Agent contact number is required')
    }),
    abnOrSelfEmployment: Yup.object().shape({
        abn : Yup.string().required('ABN is required'),
        accountantContactNumber : Yup.string().required('Accountant contact number is required'),
        accountantName : Yup.string().required('Accountant name is required'),
        businessName    : Yup.string().required('Business name is required'),
        businessType   : Yup.string().required('Business type is required'),
        companyName : Yup.string().required('Company name is required'),
        industryType : Yup.string().required('Industry type is required'),
        state : Yup.string().required('State is required'),
        yearlyGrossIncome : Yup.number().required('Yearly gross income is required').min(0, 'Yearly gross income must be more than 0')      
    }),
    homeDuties: Yup.object().shape({
      incomeType: Yup.string().required('Income type is required'),
      duration: Yup.number().required('Required').min(1, 'Must be more than 0'),
      // Add other fields specific to 'homeDuties' income type
    }),
    other: Yup.object().shape({
      incomeType: Yup.string().required('Income type is required'),
      duration: Yup.number().required('Required').min(1, 'Must be more than 0'),
      grossIncome: Yup.number().required('Gross income is required').min(0, 'Gross income must be more than 0'),
      netIncome: Yup.number().required('Net income is required').min(0, 'Net income must be more than 0'),
      frequency: Yup.string().required('Frequency is required'),
      otherIncomeType: Yup.string().required('Other income type is required')
      // Add other fields specific to 'other' income type
    }),
    governmentBenefits: Yup.object().shape({
      incomeType: Yup.string().required('Income type is required'),
      duration: Yup.number().required('Required').min(1, 'Must be more than 0'),
      grossIncome: Yup.number().required('Gross income is required').min(0, 'Gross income must be more than 0'),
      netIncome: Yup.number().required('Net income is required').min(0, 'Net income must be more than 0'),
      frequencey: Yup.string().required('Frequency is required')
      // Add other fields specific to 'governmentBenifits' income type
    }),
    // Add other income type schemas as needed
  };

  // Use a function to dynamically select the schema based on the income type
  const incomeEntrySchema = (values) => {
    let incomeCategory = '';
    switch(true){
        case ["permanentFullTimeEmployment" , "permanentPartTimeEmploymentOver25HoursPerWeek" , "permanentPartTimeEmploymentLessThan25HoursPerWeek" , "casualEmployment", "onCallCasualEmployment" , "contractorEmployment" , "workCoverEmployment"].includes(values.incomeType):
            incomeCategory = "standard";    
        break;
        case ["abnOrSelfEmployment"].includes(values.incomeType):
            incomeCategory = "business";
            break;        
        case ["investmentPropertyRentalIncome"].includes(values.incomeType):
            incomeCategory = "investment";
            break;    
        case ["tac", "governmentBenefits" , "courtOrderedChildSupport"].includes(values.incomeType):
            incomeCategory = "governmentBenefits"
            break;
        case ["homeDuties"].includes(values.incomeType):
            incomeCategory = "homeDuties";
            break;
        case ["other"].includes(values.incomeType):
            incomeCategory = "other";
            break;    
        default:
            incomeCategory = values.incomeType;
            break;
    }
    const schema = incomeCategorySchemas[incomeCategory] || Yup.object(); // Default to an empty schema if income type not found
    return schema;
  };
  

// Define the validation schema for the income object
const IncomeValidationSchema = Yup.object().shape({
    income: Yup.object().shape({
      primaryIncome: Yup.array().of(Yup.lazy(incomeEntrySchema)).required('Primary income is required'),
      secondaryIncome: Yup.array().of(Yup.lazy(incomeEntrySchema)),
      previousIncome: Yup.array().of(Yup.lazy(incomeEntrySchema)),
  }).test('check-36-months', 'Please provide the employment history for the last 36 months', function (value) {
      const { primaryIncome = [], secondaryIncome = [], previousIncome = [] } = value;

      //FROM PRIMARY AND SECONDARY INCOME DURATION PICK TH HIGHEST ONE 
      const primaryIncomeDuration = primaryIncome.reduce((acc, curr) => acc + (curr.duration || 0), 0);
      
      //GET HIGHEST DURATION FROM SECONDARY INCOME ARRAY
      const secondaryIncomeDuration = secondaryIncome.reduce((acc, curr) => Math.max(acc, curr.duration || 0), 0);

      //SUM UP ALL THE DURATION FROM PREVIOUS INCOME ARRAY
      const previousIncomeDuration = previousIncome.reduce((acc, curr) => acc + (curr.duration || 0), 0);

      const highestIncomeDuration = Math.max(primaryIncomeDuration, secondaryIncomeDuration);
      const totalDuration = highestIncomeDuration+ previousIncomeDuration; 
      return totalDuration >= 36;
  })

});

export default IncomeValidationSchema;
