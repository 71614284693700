import { useEffect, useState } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { Formik, Form, FieldArray } from 'formik';
import { FormSubmissionButton , CustomButton} from '../../../../components/HtmlComponents';
import Constants from "../../../../components/global/Constants";
import { PlusIcon } from '../../../../components/HtmlComponents/Icons'

import ListIncome from "./components/ListIncome";
import { v4 as uuid } from 'uuid';

import IncomeValidationSchema from "./schema/IncomeValidationSchema";

const Income = ({ initialValues, formRef, executeApplicationSubmissionHandler, applicationId, applicantId, openByDefault , addBackButton, backHandler}) => {
    const [isOpen, setIsOpen] = useState(openByDefault === true ? "block" : "none");
    const [incomeTypeList, setIncomeTypeList] = useState([]);
    const [income, setIncome] = useState({
        "primaryIncome": [{ "incomeType": "" }],
        "secondaryIncome": [],
        "previousIncome": []
    });

    const FetchIncomeTypes = () => {
        var options = [];
        Object.keys(Constants.INCOME_TYPES).map(key => {
            options.push({ value: key, label: Constants.INCOME_TYPES[key] });
        })
        setIncomeTypeList(options);
    }

    //ADD UNIQUE KEY TO EACH INCOME 
    async function addUniqueKeyToEachIncome(incomeClass) {
        var localIncome = [];
        var localIncomeSection = [];  //WILL CONTAINS ONLY 1 INCOME TYPE AT A TIME
        switch (incomeClass) {
            case "primaryIncome":
                localIncomeSection = initialValues.income.primaryIncome !== undefined && Array.isArray(initialValues.income.primaryIncome) && initialValues.income.primaryIncome.length > 0 ? initialValues.income.primaryIncome : [];
                break;
            case "secondaryIncome":
                localIncomeSection = initialValues.income.secondaryIncome !== undefined && Array.isArray(initialValues.income.secondaryIncome) && initialValues.income.secondaryIncome.length > 0 ? initialValues.income.secondaryIncome : [];
                break;
            case "previousIncome":
                localIncomeSection = initialValues.income.previousIncome !== undefined && Array.isArray(initialValues.income.previousIncome) && initialValues.income.previousIncome.length > 0 ? initialValues.income.previousIncome : [];
                break;
        }

        var localIncome = await localIncomeSection.map((income) => {
            income["uId"] = uuid();
            return income;
        })
        //setIncome( {...income, [incomeClass] :  localIncome }); 
        //setIncome(initialValues.income);

    }



    useEffect(() => {
        FetchIncomeTypes();
    }, []);

    useEffect(() => {
    }, [income])


 

    //UPDATE INCOEM STATE ONLY APPLICANT CHANGES
    useEffect(() => {
        if (initialValues.income !== undefined
            && initialValues.income.primaryIncome !== undefined && Array.isArray(initialValues.income.primaryIncome) && initialValues.income.primaryIncome.length > 0
            && initialValues.income.secondaryIncome !== undefined && Array.isArray(initialValues.income.secondaryIncome)
            && initialValues.income.previousIncome !== undefined && Array.isArray(initialValues.income.previousIncome)
        ) {
            setIncome(initialValues.income);
        }
        else {
            setIncome(previousState => ({
                "primaryIncome": [{ "incomeType": "" }],
                "secondaryIncome": [],
                "previousIncome": [],
            }));
        }
    }, [initialValues.income])


    useEffect(() => {
    }, [income])

    const Createincome = (incomeClass) => {
        switch (incomeClass) {
            case "secondaryIncome":
                var tmpIncome = income["secondaryIncome"];
                tmpIncome.push({ "incomeType": "" });
                setIncome(prevIncome => ({ ...prevIncome, secondaryIncome: tmpIncome }));
                break;
            case "previousIncome":
                var tmpIncome = income["previousIncome"];
                tmpIncome.push({ "incomeType": "" });
                setIncome(prevIncome => ({ ...prevIncome, previousIncome: tmpIncome }));
                break;
        }
    }

    {/***********************************DELETE SECONDARY OR PREVIOUS INCOME  *******************/ }
    const deleteIncome = (incomeClass, index) => {
        var currentValues = JSON.parse(JSON.stringify(formRef.current.values));

        switch (incomeClass) {
            case "secondaryIncome":
                var secondaryIncome = currentValues["income"]["secondaryIncome"];
                secondaryIncome.splice(index, 1);
                setIncome(prevState => ({ ...prevState, secondaryIncome: secondaryIncome }));
                break;
            case "previousIncome":
                var previousIncome = currentValues["income"]["previousIncome"];
                previousIncome.splice(index, 1);
                setIncome(prevState => ({ ...prevState, previousIncome: previousIncome }));
                break;
        }
    }

    const handler = (purpose, payload) => { }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="incoemBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <h3 className="pb-0 mb-0">Income</h3>
                    </div>

                    { /** personal infort=mation block start  */}
                    <div>
                        <div className="separator separator-dashed my-5"></div>
                        <Formik
                            validationSchema={IncomeValidationSchema}
                            initialValues={{ "income": income }}
                            innerRef={formRef}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            onSubmit={values => {
                                executeApplicationSubmissionHandler("income");
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>
                                     
                                    { /* PRIMARY INCOME */}
                                    <ListIncome key="primaryIncome"
                                        income={income["primaryIncome"]}
                                        incomeClass={"primaryIncome"}
                                        incomeTypeList={incomeTypeList}
                                        setFieldValue={setFieldValue}
                                        callback={handler}
                                        applicationId={applicationId}
                                        errors={errors}
                                    />

                                    { /* PREVIOUS INCOME */}
                                    <div className="mt-5">
                                        {<FieldArray name="income.previousIncome">
                                            {({ remove, push }) => (
                                                <>
                                                    <ListIncome
                                                        income={values.income.previousIncome}
                                                        incomeClass={"previousIncome"}
                                                        incomeTypeList={incomeTypeList}
                                                        setFieldValue={setFieldValue}
                                                        deleteIncome={remove}
                                                        errors={errors}
                                                        callback={(method, payload) => { }}
                                                        applicationId={applicationId}
                                                    />
                                                    <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => {
                                                        push({ "incomeType": "" })
                                                    }}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        Add Previous Income
                                                    </button>
                                                </>
                                            )}
                                        </FieldArray>}
                                    </div>

                                    { /* SECONDARY INCOME */}
                                    <div className="mt-5">
                                        {<FieldArray name="income.secondaryIncome">
                                            {({ remove, push }) => (
                                                <>
                                                    <ListIncome
                                                        income={values.income.secondaryIncome}
                                                        incomeClass={"secondaryIncome"}
                                                        incomeTypeList={incomeTypeList}
                                                        setFieldValue={setFieldValue}
                                                        deleteIncome={remove}
                                                        errors={errors}
                                                        callback={(method, payload) => { }}
                                                        applicationId={applicationId} />
                                                    <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => {
                                                        push({ "incomeType": "" })
                                                    }}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                            </svg>
                                                        </span>
                                                        Add Secondary Income
                                                    </button>
                                                </>
                                            )}
                                        </FieldArray>}
                                    </div>                                    
                                    <div className="col-sm-12" style={{textAlign: "right"}}>
                                        {Object.keys(errors).length === 1 && errors["income"] !== undefined && typeof errors["income"] === "string" ? <div className="error">{errors["income"]}</div> : ""}                                         
                                    </div>
                                    <div className="col-xl-12"> 
                                        <div style={{display:"flex", flexDirection:"row-reverse"}}>
                                            {Object.keys(errors).length > 0 ?
                                                <FormSubmissionButton isActive={false} label="Save & Continue" /> :
                                                <FormSubmissionButton isActive={true} label="Save & Continue" />
                                            }                                            
                                            {
                                                addBackButton === true ? 
                                                <CustomButton 
                                                buttonClassName="btn btn-sm btn-light btn-active-secondary me-5" 
                                                isActive={true} 
                                                buttonOnClick = {() => { backHandler(); }}
                                                buttonText="Go Back"                                                 
                                                />: ""
                                            }
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Income;