import { useEffect, useState } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { Formik, Form, Field, useField, useFormikContext, FieldArray } from 'formik';
import { FormSubmissionButton, CustomButton, SelectField, InputField } from '../../../../components/HtmlComponents';
import Constants from "../../../../components/global/Constants";
import ListAssets from "./components/ListAssets";
import { v4 as uuid } from 'uuid'; 


import AssetsValidationSchema from "./schema/AssetsValidationSchema";
import Notifications from "../../../../components/global/Notifications";

const Assets = ({ initialValues, formRef, executeApplicationSubmissionHandler, addBackButton, backHandler }) => {
    const [assets, setAssets] = useState([]);
    const [assetsTypeList, setAssetsTypeList] = useState([]); 

    const fetchAssetsType = () => {
        const options = Object.keys(Constants.ASSETS_TYPES).map(key => ({
            value: key,
            label: Constants.ASSETS_TYPES[key]
        }));
        setAssetsTypeList(options);
    };

    useEffect(() => { fetchAssetsType(); }, []);

 
    //UPDATE ASSETS STATE IF LOADING THE APPLICATION FROM BACKEND
    useEffect(() => {
        const updateAssetsStateAndAddUniqueKeyToEachAsset = () => {
            const localAssets = initialValues.assets.map(asset => ({  ...asset,  uId: uuid() }));
            setAssets(localAssets);
        };
        updateAssetsStateAndAddUniqueKeyToEachAsset();
    }, [initialValues.assets]);

    return (
        <div className="card mb-5 mb-xxl-8" key="assetsBlock">
            <div className="card-body pt-6 pb-6">
                <div className="d-flex flex-wrap flex-sm-nowrap">
                    <h3 className="pb-0 mb-0">Assets</h3>
                </div>
                <div>
                    <div className="separator separator-dashed my-5"></div> 
                    <Formik
                        validationSchema={AssetsValidationSchema}
                        initialValues={{ "assets": assets }}
                        innerRef={formRef}
                        enableReinitialize={true}
                        validateOnBlur={true}
                        onSubmit={() => {
                            //CHECK THE LENGTH OF ASSETS - ATLEAST 1 ASSET IS REQUIRED
                            if (formRef.current.values.assets.length === 0 || 
                                formRef.current.values.assets?.[0]?.assetType === ""
                            ) {
                                Notifications("error", "Atleast 1 asset is required");
                            }
                            else{
                                executeApplicationSubmissionHandler("assets");
                            }
                        }}
                    >
                        {({ errors, touched, setFieldValue, values }) => (
                            <Form>
                                <FieldArray name="assets">
                                    {({ remove, push }) => (
                                        <>
                                            <ListAssets 
                                                assets={values.assets} 
                                                assetsTypeList={assetsTypeList} 
                                                setFieldValue={setFieldValue} 
                                                deleteAsset={remove} 
                                                errors={errors}
                                            />
                                            <button 
                                                className="btn btn-sm btn-flex btn-light-primary" 
                                                type="button" 
                                                onClick={() => push({ assetType: "", assetValue: '', assetOwnership: '' })}
                                            >
                                                <span className="svg-icon svg-icon-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                    </svg>
                                                </span>
                                                Add Asset
                                            </button>
                                        </>
                                    )}
                                </FieldArray>

                                <div className="col-xl-12">
                                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                        {Object.keys(errors).length > 0 ?
                                            <FormSubmissionButton isActive={false} label="Save & Continue" /> :
                                            <FormSubmissionButton isActive={true} label="Save & Continue" />
                                        }
                                        {
                                            addBackButton === true ?
                                                <CustomButton
                                                    buttonClassName="btn btn-sm btn-light btn-active-secondary me-5"
                                                    isActive={true}
                                                    buttonOnClick={() => { backHandler(); }}
                                                    buttonText="Go Back"
                                                /> : ""
                                        }
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>                        
                </div>
            </div>
        </div>
    );
};

export default Assets;
