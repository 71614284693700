import './App.css';
import { Toaster } from 'react-hot-toast';
import React from 'react';


import { BrowserRouter, Routes, Route } from 'react-router-dom';


/** APPLICATIONS ROUTES **/
import ConsumerApplication from './modules/consumerApplication/ConsumerApplication';
//import ListApplications from './components/applications/ListApplications';
//import ApplicationFiles from './components/applications/ApplicationFiles';

import Files from './components/files/Files';


function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ConsumerApplication />}></Route>
          <Route path="/token" element={<ConsumerApplication />}></Route>
          <Route path="/files" element={<Files />}></Route>
        </Routes>
      </BrowserRouter>

      <Toaster />  { /** FOR NOTIFICATIONS **/}
    </div>

  );
}

export default App;