
module.exports = {

    //REFERRER USER OCCUPATION LIST
    "REFERRER_OCCUPATIONS_LIST" : {"accountant" : "Accountant" , "dealerPrincipal" : "Dealer Principal" , "generalManager" : "General Manager", "manager" : "Manager", "businessPartner" : "Business Partner" , "sales" : "Sales", "salesManager" : "Sales Manager", "businessManager" : "Business Manager", "financeBroker" : "Finance Broker"},


    //APPLICATION RELATED
    "APPLICATION_CATEGORY" : {'individual' : "Individual" , 'joint' : "Joint" ,'company' : "Company"},
    "BODY_TYPES": ["sedan", "hatch", "coupe", "wagon", "van", "utility", "other"],
    "VEHICLE_CONDITION": ["new", "used", "demo"],
    "TRANSMISSION": ["automatic", "manual"],
    "TITLE": ["mr", "ms", "mrs", "miss"],
    "GENDER": ["male", "female"],
    "LOAN_TYPE": ["consumer", "commercial"],

    "LOAN_PURPOSES":{
        "carDealership":"Car - Dealership", "carPrivate" : "Car - Private", "caravanTrailer" : "Caravan / Trailer", "lineOfCredit":"Line of Credit",     
        "marine" : "Marine", "motorcycle" : "Motorcycle", "personalLoan" : "Personal Loan", "insuranceOnly" : "Insurance Only",        
        "equipment": "Equipment loans", "farmMachinery" : "Farm Machinery", "earthMovingHaulage": "Earth Moving Haulage",
        "truck" : "Truck", "goKart" : "Go Kart", "debtConsolidation" : "Debt Consolidation", "refinance" : "Refinance", "other" : "Other"
    },
    
    "BEST_CONTACT_METHOD": ["email", "call"],
    "RELATIONSHIP_STATUS": { "single": "Single", "married": "Married", "defacto": "Defacto", "separated": "Separated", "divorced": "Divorced", "widowed": "Widowed" },
    "RESIDENCY_STATUS": { "citizen": "Citizen", "pr": "Permanent Resident", "other": "Other" },
    "RESIDENTIAL_STATUS": { "rentingAgent": "Renting Agent", "rentingPrivate": "Renting Private", "boardingOther": "Boarding (Other)", "boardingLivingWithFamily": "Boarding(Living with family)", "ownYourHouseOwnOutright": "Own your house (own outright)", "buyingYourHouseOweMoneyOn": "Buying your house (owe money on)", "suppliedByEmployer": "Supplied by employer", "caravanPark": "Caravan Park", "hotelOrHostel": "Hotel or Hostel" },
    "STATES": { "vic": "Victoria", "nsw": "New South Wales", "sa": "South Australia", "qld": "Queensland", "act": "Australian Capital Territory ", "tas": "Tasmania", "nt": "Northern Territory", "wa": "Western Australia", "international": "International" },
    "LICENSE_TYPES": { "full": "Full", "probation": "Probation", "learner": "Learner", "car-f": "Car - F", "car-p": "Car - P", "car-l": "Car - L", "bike-f": "Bike - F", "bike-p": "Bike - P", "bike-l": "Bike - L", "marine": "Marine", "heavy": "Heavy", "tractor": "Tractor", "other": "Other" },

    "FREQUENCY": { "weekly": "Weekly", "fortnightly": "Fortnightly", "monthly": "Monthly", "quarterly": "Quarterly", "yearly": "Yearly" },
    "EXPENSES_TYPES": {
        "utilities": "Utilities (e.g. Electricity, Gas and Water)", "household": "Household (e.g. Repairs, Maintenance & Gardening )",
        "communicationsAndEntertainment": "Communications & Entertainment (e.g. Mobile Phone, Internet, Pay TV , Streaming Services )",
        "basic": "Basic (e.g. Groceries, Food, Takeaway, Alcohol, Toiletries & Cleaning )", "clothing": "Clothing (e.g. Clothing for Adults and Children)",
        "personalAndLifeInsurance": "Personal & Life Insurance (e.g. Life, Disability, Income Protection)", "transportAndTravel": "Transport & Travel (e.g. Petrol, Parking & Public Transport )",
        "homeInsurance": "Home Insurance (e.g. Home & Content Insurance )", "childSupport": "Child Support (e.g. Child Support, Maintenance & Alimony Payments )",
        "vehicleInsurance": "Vehicle Insurance (e.g. For all vehicles )", "childrenEducation": "Children Education (e.g. Childcare, Public School Fees, Uniforms, Books, Excursions & Camps )",
        "additionalVehicleExpenses": "Additional Vehicle Expenses (e.g. Expenses associated with additional cars, motorbikes, boats, jet ski's etc)", "privateSchoolFee": "Private School Fee (e.g. Private School Fee )",
        "investmentExpenses": "Investment Expenses (e.g. body corporate, land tax, rates, insurance )", "educationLoans": "Education Loans (e.g. HECS, HELP)",
        "otherExpenses": "Other Expenses (e.g. donations, overseas travel, gardener, cleaner)", "medical": "Medical (e.g. Health Insurance, Dental, Optical & Pharmaceutical)"
    },
    "INCOME_TYPES": {
        "permanentFullTimeEmployment": "Permanent - Full Time Employment", "permanentPartTimeEmploymentOver25HoursPerWeek": "Permanent (Part Time Employment -  work over 25 hours per week)", "permanentPartTimeEmploymentLessThan25HoursPerWeek": "Permanent (Part Time Employment - work less than 25 hours per week)", "casualEmployment": "Casual Employment", "onCallCasualEmployment": "On Call Casual Employment", "contractorEmployment": "Contractor Employment",
        "abnOrSelfEmployment": "ABN | Self Employed - Employment", "investmentPropertyRentalIncome": "Investment Property Rental Income", "tac": "TAC", "workCoverEmployment": "Work cover - Employment", "governmentBenefits": "Government Benefits", "courtOrderedChildSupport": "Court ordered child support", "homeDuties": "Home Duties", "other": "Other"
    },


    "ASSETS_TYPES": { "car": "Car", "caravan": "Caravan", "motorBike": "Motor Bike", "boat": "Boat", "jetSki": "Jet-ski", "truck": "Truck", "houseContents": "House Contents", "house": "House", "land": "Land", "investmentProperties": "Investment Properties", "superannuation": "Superannuation", "cashInBank": "Cash in Bank", "other": "Other" },
    "ASSET_OWNERSHIP_TYPES": { "ownOutright": "Own outright", "oweMoneyOn": "Owe money on" },


    "DEBT_TYPES": { "personal": "Personal loan", "car": "Car loan", "motorBike": "Motor Bike loan", "boat": "Boat loan", "caravan": "Caravan loan", "jetSki": "Jet-Ski loan", "truck": "Truck loan", "house": "House loan", "land": "Land loan", "investmentProperties": "Investment Properties", "hirePurchase": "Hire purchase", "creditCard": "Credit card", "storeCard": "Store card", "chargeCard": "Charge card", "overdraft": "Overdraft", "buyNowPayLater": "Buy Now Pay Later", "mortgage": "Mortgage", "other": "Other" },

    "INSURANCE_TYPES" :{ "cci" : "CCI" , "comprehensive" : "Comprehensive" , "warrenty":"Warranty" , "gap" : "GAP" , "lti" : "LTI" , "rti" : "RTI"},
  

    //STYLE RELATED CONSTANTS
    "TEXTFIELD_HEIGHT" : "35px",

    "DECLINED_REASONS" : {
        "adverseCreditFile" : "Adverse Credit File", 
        "poorCurrentOrPreviousLoanHistory" : "Poor / Slow Current or Previous Loan History",
        "bankAccountConduct" : "Bank Account Conduct (Dishonours etc)",
        "capacity" : "Capacity / Serviceability", 
        "employmentStatus" : "Employment Status",        
        "residencyStatus" : "Residency Status (Visa / PR etc)",
        "assetUnacceptable" : "Asset Unacceptable", 
        "referralWithdrawnByCustomer" : "Referral Withdrawn by Customer", 
        "referralWithdrawnByReferrer" : "Referral Withdrawn by Referrer", 
        "referralNotResponding" : "Referral Not Responding / Referral Filed", 
        "other" : "Other"
    },
    "EMAIL_TEMPLATE_RECIPIENT_TYPES" :  { 'referrerUser' : 'Referrer User' },
    
    
}



/*
    "LOAN_PURPOSES": {
        "car": "Car", "bike": "Bike", "abn": "ABN", "marine": "Marine", "caravan": "Caravan", "floats": "Floats", "home": "Home", "livestockApGold": "Livestock / Ap Gold",
        "carPrivateSaleCarLoans": "Car - Private Sale Car Loans", "carDealerCarLoans": "Car - Dealer Car Loans",
        "carAbnCarLoans": "Car - ABN Car Loans", "carLowDocCarLoans": "Car - Low-doc Car Loans",
        "carUberCarLoans": "Car - Uber Car Loans", "carRefinanceCarLoans": "Car - Refinance Car Loans",
        "carNovatedCarLoans": "Car - Novated Car Loans", "carImportedGreyCarLoans": "Car - Imported (Grey) Car Loans",
        "carRepairableWriteOff": "Car - Repairable Write - Off", "bikePrivateSaleBikeLoans": "Bike - Private Sale Bike Loans",
        "bikeDealerBikeLoans": "Bike - Dealer Bike Loans", "bikeAbnBikeLoans": "Bike - ABN Bike Loans",
        "bikeLowDocBikeLoans": "Bike - Low - Doc Bike Loans", "bikeImportedGreyBikeLoans": "Bike - Imported (Grey) Bike Loans",
        "bikeDirtBikeLoans": "Bike - Dirt Bike Loans", "bikeGoKartLoans": "Bike - Go Kart Loans",
        "abnCarLoans": "ABN - Car Loans", "abnTruckLoans": "ABN - Truck Loans",
        "abnBikeLoans": "ABN - Bike Loans", "abnEquipmentLoans": "ABN - Equipment Loans",
        "abnFarmMachineryLoans": "ABN - Farm Machinery Loans", "abnEarthMovingHeavyHaulageLoans": "ABN - Earth Moving Heavy Haulage Loans",
        "abnOtherAbnLoans": "ABN - Other ABN Loans", "marineRunaboutLoans": "Marine - Runabout Loans",
        "marineJetskiLoans": "Marine - Jetski Loans", "marineCrusierLoans": "Marine - Crusier Loans",
        "marineYachtLoans": "Marine - Yacht Loans", "marineTrailerLoans": "Marine - Trailer Loans",
        "marineOffBeachLoans": "Marine - Off Beach Loans", "marineEngineLoans": "Marine - Engine Loans",
        "caravanFifthWheelerLoans": "Caravan - Fifth Wheeler Loans", "caravanMotorHomeLoans": "Caravan - Motor Home Loans",
        "caravanPopTopLoans": "Caravan - Pop Top Loans", "caravanOffRoadLoans": "Caravan - Off Road Loans",
        "caravanCamperTrailerLoans": "Caravan - Camper Trailer Loans", "caravanWindUpLoans": "Caravan - Wind Up Loans",
        "floatsDealerFloatLoans": "Floats - Dealer Float Loans", "floatsPrivateSaleFloatLoans": "Floats - Private Sale Float Loans",
        "homeRefinanceHomeLoans": "Home - Refinance Home Loans", "homePurchaseHomeLoans": "Home - Purchase Home Loans",
        "homeConstructionHomeLoans": "Home - Construction Home Loans", "homeInvestmentHomeLoans": "Home - Investment Home Loans",
        "otherPersonalLoans": "Other - Personal Loans", "OtherDebtConsolidationLoans": "Other - Debt Consolidation Loans",
        "otherMedicalLoans": "Other - Medical Loans", "otherTravelLoans": "Other - Travel Loans",
        "trailer": "Trailer", "insuranceOnly": "Insurance Only"
    },

*/