import { useState, React, Children } from "react";


const RadioGroup = ({ fieldName, defaultSelectedOption, options, setFieldValue }) => {

    if(defaultSelectedOption === true || defaultSelectedOption === false){ defaultSelectedOption = defaultSelectedOption.toString();}

    const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);

    const updateOnValueChange = (e) => {
        console.log("radio button ---------------  ", ` field name-------------   ${fieldName}`,  e.target.value);
        setSelectedOption(e.target.value);
        setFieldValue(fieldName, e.target.value == 'true' ? true : (e.target.value == 'false' ? false: e.target.value ) );
    }
    const RadioButtons = () => {
        return Children.toArray(
            options.map((option) =>
                <>
                    <label key={option.value.toString} style={{ paddingRight: "10px", "marginTop": "10px" }}>
                        <input
                            type="radio"
                            className="form-check-input"
                            value={option.value.toString()}
                            checked={selectedOption === option.value.toString()}
                            onChange={updateOnValueChange}
                            name={fieldName}
                        />
                        <span style={{ paddingLeft: "5px" }}> {option.label} </span>
                    </label>

                </>
            )
        )
    }
    return (<RadioButtons />)
}

export default RadioGroup;