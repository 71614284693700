import { SelectField, InputField, IconButton, InputGroup, CustomButton } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field } from 'formik';
import Constants from '../../../../../components/global/Constants';
import { useEffect, useState } from 'react';

import { capitalizeFirst } from '../../../../../helpers';

const BuyingMotorVehicleItem = ({ errors, touched, setFieldValue, lookupCarInfo }) => {
    const [bodyTypes, setBodyTypes] = useState([]);
    const [condition, setCondition] = useState([]);
    const [transmission, setTransmission] = useState([]);
    const [states, setStates] = useState([]);


    useEffect(() => {
        //BODY TYPES
        var bodyTypesOptions = [];
        Constants.BODY_TYPES.map(item => {
            bodyTypesOptions.push({ value: item, label: capitalizeFirst(item) })
        })
        setBodyTypes(bodyTypesOptions);

        //Condition
        var conditoinOptions = [];
        Constants.VEHICLE_CONDITION.map(item => {
            conditoinOptions.push({ value: item, label: capitalizeFirst(item) })
        })
        setCondition(conditoinOptions);

        //TRANSMISSION
        var transmissionOptions = [];
        Constants.TRANSMISSION.map(item => {
            transmissionOptions.push({ value: item, label: capitalizeFirst(item) })
        })
        setTransmission(transmissionOptions);

        //STATE (ETC VIC , NSW ETC)
        var stateOptions = [];
        Object.keys(Constants.STATES).map(index => {
            stateOptions.push({ value: index, label: Constants.STATES[index] })
        })
        setStates(stateOptions);

    }, [])


    return (
        <>
            <div className="row mb-5">
                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Condition</label>
                    <Field name="buyingItem.motorVehicles[0].condition">
                        {({ field }) => (
                            <SelectField
                                fieldName="buyingItem.motorVehicles[0].condition"
                                defaultSelectedOption={{ value: field.value, label: '' }}
                                options={condition}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                    {errors.buyingItem && errors.buyingItem.motorVehicles && errors.buyingItem.motorVehicles[0]  && errors.buyingItem.motorVehicles[0].condition  ? <div  className="error"> {errors.buyingItem.motorVehicles[0].condition} </div> : "" }                                                                                
                </div>
                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Year</label>
                    <Field name="buyingItem.motorVehicles[0].year" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].year"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                    {errors.buyingItem && errors.buyingItem.motorVehicles && errors.buyingItem.motorVehicles[0]  && errors.buyingItem.motorVehicles[0].year  ? <div  className="error"> {errors.buyingItem.motorVehicles[0].year} </div> : "" }                                                                                
                </div>
                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Make</label>
                    <Field name="buyingItem.motorVehicles[0].make" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].make"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={20}
                            />
                        )}
                    </Field>
                    {errors.buyingItem && errors.buyingItem.motorVehicles && errors.buyingItem.motorVehicles[0]  && errors.buyingItem.motorVehicles[0].make  ? <div  className="error"> {errors.buyingItem.motorVehicles[0].make} </div> : "" }
                </div>
                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Model</label>
                    <Field name="buyingItem.motorVehicles[0].model" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].model"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={20}
                            />
                        )}
                    </Field>
                    {errors.buyingItem && errors.buyingItem.motorVehicles && errors.buyingItem.motorVehicles[0]  && errors.buyingItem.motorVehicles[0].model  ? <div  className="error"> {errors.buyingItem.motorVehicles[0].model} </div> : "" }
                </div>
                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Body Type</label>
                    <Field name="buyingItem.motorVehicles[0].bodyType">
                        {({ field }) => (
                            <SelectField
                                fieldName="buyingItem.motorVehicles[0].bodyType"
                                defaultSelectedOption={{ value: field.value, label: '' }}
                                options={bodyTypes}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                    {errors.buyingItem && errors.buyingItem.motorVehicles && errors.buyingItem.motorVehicles[0]  && errors.buyingItem.motorVehicles[0].bodyType  ? <div  className="error"> {errors.buyingItem.motorVehicles[0].bodyType} </div> : "" }
                </div>
                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Transmission</label>
                    <Field name="buyingItem.motorVehicles[0].transmission">
                        {({ field }) => (
                            <SelectField
                                fieldName="buyingItem.motorVehicles[0].transmission"
                                defaultSelectedOption={{ value: field.value, label: '' }}
                                options={transmission}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                    {errors.buyingItem && errors.buyingItem.motorVehicles && errors.buyingItem.motorVehicles[0]  && errors.buyingItem.motorVehicles[0].transmission  ? <div  className="error"> {errors.buyingItem.motorVehicles[0].transmission} </div> : "" }                    
                </div>
                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Build Date</label>
                    <Field name="buyingItem.motorVehicles[0].buildDate" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].buildDate"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Compliance Date</label>
                    <Field name="buyingItem.motorVehicles[0].complianceDate" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].complianceDate"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>
                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">KM's /Hours</label>
                    <Field name="buyingItem.motorVehicles[0].totalKilometer" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].totalKilometer"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={7}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className=" fs-8 fw-bold mb-2">Registration #</label>
                    <Field name="buyingItem.motorVehicles[0].registrationNumber" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].registrationNumber"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={7}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Registartion State</label>
                    <Field name="buyingItem.motorVehicles[0].registrationState">
                        {({ field }) => (
                            <SelectField
                                fieldName="buyingItem.motorVehicles[0].registrationState"
                                defaultSelectedOption={{ value: field.value, label: '' }}
                                options={states}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">VIN</label>
                    <Field name="buyingItem.motorVehicles[0].vin" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].vin"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={36}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">NvIC</label>
                    <Field name="buyingItem.motorVehicles[0].nvic" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].nvic"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={36}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Engine #</label>
                    <Field name="buyingItem.motorVehicles[0].engineNumber" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].engineNumber"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={36}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Color</label>
                    <Field name="buyingItem.motorVehicles[0].color" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].color"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={20}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-md-2 col-sm-3  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">Estimated Delivery Date</label>
                    <Field name="buyingItem.motorVehicles[0].estimatedDeliveryDate" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].estimatedDeliveryDate"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </Field>
                </div>

                <div className="col-md-12 col-sm-12  mb-4 fv-row fv-plugins-icon-container">
                    <label className="required fs-8 fw-bold mb-2">description</label>
                    <Field name="buyingItem.motorVehicles[0].description" >
                        {({ field }) => (
                            <InputField
                                fieldName="buyingItem.motorVehicles[0].description"
                                fieldValue={field.value}
                                setFieldValue={setFieldValue}
                                charactersLimit={150}
                            />
                        )}
                    </Field>
                    {errors.buyingItem && errors.buyingItem.motorVehicles && errors.buyingItem.motorVehicles[0]  && errors.buyingItem.motorVehicles[0].description  ? <div  className="error"> {errors.buyingItem.motorVehicles[0].description} </div> : "" }                                  
                </div>
            </div>
        </>
    )

}


export default BuyingMotorVehicleItem;