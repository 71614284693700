
import { useEffect, useState } from 'react';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { FormSubmissionButton, CustomButton, SelectField, InputField, IconButton } from '../../../../../components/HtmlComponents';
import Constants from '../../../../../components/global/Constants';

import StandardIncome from './StandardIncome';
import GovernmentIncome from './GovernmentIncome';
import InvestmentIncome from './InvestmentIncome';
import HomeDuties from './HomeDuties';
import OtherIncome from './OtherIncome';
import SelfIncome from './SelfIncome';

//incomeClass => primaryIncome, secondaryIncome, previousIncome
const SingleIncome = ({ index, errors, incomeType, incomeClass, incomeTypeList, frequencyList, setFieldValue, callback, deleteIncome, applicationId }) => {
    const [selectedIncomeType, setSelectedIncomeType] = useState("");

    const decideDetailedBlockOnChange = (fieldName, value, setFieldValue) => {
        setSelectedIncomeType(value);
    }


    useEffect(() => { }, [selectedIncomeType]);

    useEffect(() => {
        setSelectedIncomeType(incomeType);
    }, [incomeType]);

    const getIncomeTypeLabel = () => {
        var label = '';
        switch (incomeClass) {
            case "primaryIncome": label = "Primary Income Type"; break;
            case "secondaryIncome": label = "Secondary Income Type"; break;
            case "previousIncome": label = "Previous Income Type"; break;
        }
        return label;
    }

    return (
        <>
            <div className="row mb-5" style={{ borderBottom: "1px dotted #edebeb" }}>
                <div className="col-sm-3 col-md-4 mb-5 fv-row fv-plugins-icon-container" >
                    <label className="fs-8 fw-bold mb-2">{getIncomeTypeLabel()} <IconButton buttonOnClick={() => { deleteIncome(index) }} iconType="-" buttonStyle={{ border: "0px", backgroundColor: "transparent" }} iconColor="orange" /></label>
                    <Field name={"income[" + incomeClass + "][" + index + "].incomeType"}>
                        {({ field }) => (
                            <SelectField
                                fieldName={"income[" + incomeClass + "][" + index + "].incomeType"}
                                defaultSelectedOption={{ value: field.value, label: "" }}
                                options={incomeTypeList}
                                setFieldValue={setFieldValue}
                                callback={decideDetailedBlockOnChange}
                            />
                        )}
                    </Field>
                </div>

                {
                    ["permanentFullTimeEmployment", "permanentPartTimeEmploymentOver25HoursPerWeek", "permanentPartTimeEmploymentLessThan25HoursPerWeek", "casualEmployment", "onCallCasualEmployment", "workCoverEmployment", "contractorEmployment"].includes(selectedIncomeType) ?
                        <StandardIncome
                            prefix={"income[" + incomeClass + "][" + index + "]."}
                            setFieldValue={setFieldValue}
                            frequencyList={frequencyList}
                            errors={errors}
                        /> : ""
                }

                {
                    ["tac", "governmentBenefits", "courtOrderedChildSupport"].includes(selectedIncomeType) ?
                        <GovernmentIncome
                            prefix={"income[" + incomeClass + "][" + index + "]."}
                            setFieldValue={setFieldValue}
                            frequencyList={frequencyList}
                            errors={errors}
                        /> : ""
                }
                {
                    ["investmentPropertyRentalIncome"].includes(selectedIncomeType) ?
                        <InvestmentIncome
                            prefix={"income[" + incomeClass + "][" + index + "]."}
                            setFieldValue={setFieldValue}
                            frequencyList={frequencyList}
                            errors={errors}
                        /> : ""
                }
                {
                    ["homeDuties"].includes(selectedIncomeType) ?
                        <HomeDuties
                            prefix={"income[" + incomeClass + "][" + index + "]."}
                            setFieldValue={setFieldValue}
                            errors={errors}
                        /> : ""
                }
                {
                    ["other"].includes(selectedIncomeType) ?
                        <OtherIncome
                            prefix={"income[" + incomeClass + "][" + index + "]."}
                            setFieldValue={setFieldValue}
                            frequencyList={frequencyList}
                            errors={errors}
                        /> : ""
                }
                {
                    ["abnOrSelfEmployment"].includes(selectedIncomeType) ?
                        <SelfIncome
                            prefix={"income[" + incomeClass + "][" + index + "]."}
                            setFieldValue={setFieldValue}
                            frequencyList={frequencyList}
                            applicationId={applicationId}
                            errors={errors}
                        /> : ""
                }



            </div>
        </>
    )

}




export default SingleIncome; 