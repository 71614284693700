import{capitalizeFirstLowercaseRest , capitalizeFirstOfEachWord } from '../../../helpers'
const InputField = ({ fieldName, placeholder , fieldValue, setFieldValue, capitalizeFirstLetter = false, charactersLimit = 0, allowedCharacters = [] }) => {
    return (
        <>
            <input
                type="text"
                className="form-control"
                style={{ height: "35px", fontWeight: "400" }}
                placeholder={placeholder}
                aria-label={placeholder}
                value= {fieldValue}
                onChange={(e) => {
                    var inputString = e.target.value;
                    
                    // Remove specific words: "delete" and "select"
                    inputString = inputString.replace(/\b(delete|select|from)\b/gi, '');

                    if(Array.isArray(allowedCharacters) && allowedCharacters.length > 0){
                        console.log("allowedCharacters " , allowedCharacters);
                        let allowRegex = allowedCharacters.map(char => '\\' + char).join('|'); // Escaping special characters
                        let regexPattern = new RegExp(`[^a-zA-Z0-9\\s${allowRegex}]`, 'g');
                        console.log("regexPattern " , regexPattern);

                        inputString = inputString.replace(regexPattern, ' ');
                    }
                    else{
                        console.log(" line 27 - no extra special characters -- ");
                        // Remove special characters, but allow spaces
                        inputString = inputString.replace(/[^a-zA-Z0-9\s]/g, ' ');
                    }
 


                    // Replace multiple spaces with a single space
                    inputString = inputString.replace(/\s\s+/g, ' '); //.trim();

                    //LIMIT THE CHARACTERS TO THE PROVIDED LIMIT 
                    if(charactersLimit > 0 && inputString.length > charactersLimit){ inputString = inputString.slice(0, charactersLimit) }
                    
                    
                    //CAPITALIZE FIRST LETTER
                    if(capitalizeFirstLetter && inputString.length > 0){ inputString = capitalizeFirstOfEachWord(inputString) }
                    setFieldValue(fieldName, inputString) 
                }}
            />
        </>
    )
}

export default InputField; 