import { useEffect, useState } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { Formik, Form, FieldArray } from 'formik';
import { FormSubmissionButton, CustomButton, CheckBox } from '../../../../components/HtmlComponents';
import Constants from "../../../../components/global/Constants";
import ListDebts from "./components/ListDebts";
import { getUniqueId } from "../../../../helpers";
import { v4 as uuid } from 'uuid';

import DebtsValidationSchema from "./schema/DebtsValidationSchema";
import Notifications from "../../../../components/global/Notifications";

import { setCookie, getCookie, deleteCookie } from "../../../../utils/Cookies";

const Debts = ({ initialValues, formRef, executeApplicationSubmissionHandler, applicationId, applicantId, openByDefault, addBackButton, backHandler }) => {
    const [isOpen, setIsOpen] = useState(openByDefault === true ? "block" : "none");
    const [debtsTypeList, setDebtsTypeList] = useState([]);
    const [debts, setDebts] = useState([]);
    const [noLiabilities, setNoLiabilities] = useState(getCookie("noLiabilities") !== undefined ? getCookie("noLiabilities") === 'true' ? true : false : false);


    const FetchDebtsType = () => {
        var options = [];
        Object.keys(Constants.DEBT_TYPES).map(key => { options.push({ value: key, label: Constants.DEBT_TYPES[key] }); })
        setDebtsTypeList(options);
    }

    useEffect(() => {
        FetchDebtsType();

        /*
        //RETRIEVE THE noLiabilities COOKIE AND UPDATE THE STATE
        const updateNoLIabilitiesStateBasedOnCookies = () => {
            var noLiabilitiesCookie = getCookie("noLiabilities");
            if (noLiabilitiesCookie !== undefined) {
                setNoLiabilities(noLiabilitiesCookie === 'true' ? true : false);
            }
        }
        updateNoLIabilitiesStateBasedOnCookies();*/
    }, []);




    useEffect(() => {
        var assets = initialValues.assets;
        if (Array.isArray(initialValues.debts) && initialValues.debts.length > 0) {
            setDebts(initialValues.debts);
        }
        else if (Array.isArray(initialValues.debts) && initialValues.debts.length == 0 && Array.isArray(assets) && assets.length > 0) {
            //setDebts([]);
            var tempDebts = [];

            assets.map(singleAsset => {
                if (singleAsset.assetType !== undefined && singleAsset.assetOwnership !== undefined && ["house", "land", "car", "motorBike", "boat", "caravan", "jetSki", "truck", "investmentProperties"].includes(singleAsset.assetType) && singleAsset.assetOwnership == "oweMoneyOn") {
                    tempDebts.push({ "debtType": singleAsset.assetType, assetValue: singleAsset.assetValue !== undefined ? singleAsset.assetValue : 0, "financialInstitution": '', "repaymentFrequency": '' })
                }
            })
            setDebts(tempDebts);
        }
        else {
            setDebts([]);
        }
    }, [initialValues.debts]);



    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="debtBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <h3 className="pb-0 mb-0">Liabilities</h3>
                    </div>

                    { /** personal infort=mation block start  */}
                    <div>
                        <div className="separator separator-dashed my-5"></div>
                        <Formik
                            validationSchema={DebtsValidationSchema}
                            initialValues={{ "debts": debts }}
                            innerRef={formRef}
                            enableReinitialize={true}
                            validateOnBlur={false}
                            onSubmit={values => {
                                console.log("VALUES", values);
                                console.log("NO LIABILITIES", noLiabilities);
                                //IT SHOULD HAVE ATLEAST 1 LIABILITY OF NO LIABILITY CHECKBOX SHOUL DBE CHECKED (noLiabilities should be true if no liability is provided) 

                                if (noLiabilities === false && formRef.current.values.debts.length == 0) {
                                    Notifications("error", "Please provide at least one liability or check the 'I have no liabilities' checkbox");
                                    return;
                                }

                                executeApplicationSubmissionHandler("debts");
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>

                                    {<FieldArray name="debts">
                                        {({ remove, push }) => (
                                            <>
                                                <ListDebts
                                                    debts={values.debts}
                                                    debtsTypeList={debtsTypeList}
                                                    setFieldValue={setFieldValue}
                                                    deleteDebt={remove}
                                                    errors={errors}
                                                />
                                                <button className=" btn btn-sm btn-flex btn-light-primary" type="button" onClick={() => {
                                                    push({ "debtType": "", "financialInstitution": '', "repaymentFrequency": '' })
                                                }}>
                                                    <span className="svg-icon svg-icon-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                            <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                            <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                    Add Liability
                                                </button>
                                            </>
                                        )}
                                    </FieldArray>}

                                    { /** CREATE A standard CHECKBOX (not toggle ) THAT WILL UPDATE THE STATE IF THE USER HAS A LIABILITY OR NOT - noLIabilities  */}

                                    <div className="form-check" style={{ marginTop: "20px" }}>
                                        <CheckBox
                                            name="noLiabilities"
                                            label="I have no liabilities"
                                            defaultChecked={noLiabilities}
                                            setFieldValue={(name, value) => {
                                                console.log("ONCHANGE", name, value);
                                                setNoLiabilities(value);
                                                //CREATE COOKIE FOR noLiabilities
                                                setCookie("noLiabilities", value);
                                            }}
                                        />
                                        <label className="form-check-label fw-bold" htmlFor="noLiabilities">I have no liabilities</label>
                                    </div>

                                    <div className="col-xl-12">
                                        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                            {Object.keys(errors).length > 0 ?
                                                <FormSubmissionButton isActive={false} label="Save & Continue" /> :
                                                <FormSubmissionButton isActive={true} label="Save & Continue" />
                                            }
                                            {
                                                addBackButton === true ?
                                                    <CustomButton
                                                        buttonClassName="btn btn-sm btn-light btn-active-secondary me-5"
                                                        isActive={true}
                                                        buttonOnClick={() => { backHandler(); }}
                                                        buttonText="Go Back"
                                                    /> : ""
                                            }
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Debts;