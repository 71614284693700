import { FormSubmissionButton, CustomButton, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import {readNestedErrorByPath} from '../../../../../helpers';


const OtherDebt = ({prefix , setFieldValue , frequencyList, errors }) => {
    return (
        <>
            <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Financial Institution</label>
                <Field name= { prefix+"financialInstitution"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"financialInstitution"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"financialInstitution").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"financialInstitution") } </div> : "" }
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Amount Borrowed</label>
                <Field name= { prefix+"amountBorrowed"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"amountBorrowed"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"amountBorrowed").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"amountBorrowed") } </div> : "" }
            </div>             
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Balance Remaining</label>
                <Field name= { prefix+"balanceRemaining"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"balanceRemaining"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"balanceRemaining").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"balanceRemaining") } </div> : "" }
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Repayment</label>
                <Field name= { prefix+"repaymentAmount"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"repaymentAmount"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"repaymentAmount").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"repaymentAmount") } </div> : "" }
            </div> 
            

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Frequency</label>
                <Field name=  {prefix+"repaymentFrequency"}>
                    {({ field }) => (
                        <SelectField
                            fieldName = {prefix+"repaymentFrequency"}
                            defaultSelectedOption={{ value: field.value !== undefined ? field.value : "", label: "" }}
                            options={frequencyList}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"repaymentFrequency").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"repaymentFrequency") } </div> : "" }
            </div>                 
        </>
    )
}

export default OtherDebt;