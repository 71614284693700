import * as Yup from 'yup';

// Define the validation schema for a single expense item
const expenseItemSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf([
      'utilities', 'household', 'communicationsAndEntertainment', 'basic', 'clothing', 
      'personalAndLifeInsurance', 'transportAndTravel', 'homeInsurance', 'childSupport', 
      'vehicleInsurance', 'childrenEducation', 'additionalVehicleExpenses', 
      'privateSchoolFee', 'investmentExpenses', 'educationLoans', 'otherExpenses', 
      'medical'
    ])
    .required('Expense type is required'),
  amount: Yup.number()
    .typeError('Amount must be a number')
    .min(0, 'Amount cannot be negative')
    .required('Amount is required'),
  frequency: Yup.string()
    .required('Frequency is required')
});

// Define the validation schema for the expenses array
const expensesSchema = Yup.array()
  .of(expenseItemSchema)
  .required('Expenses are required')
  .min(0, 'At least one expense is required');

// Example usage in a formik form
const ExpensesValidationSchema = Yup.object().shape({
  expenses: expensesSchema
});

export default ExpensesValidationSchema;
