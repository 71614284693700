import { FormSubmissionButton, CustomButton, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import {readNestedErrorByPath} from '../../../../../helpers';

const Overdraft = ({prefix , setFieldValue , frequencyList, errors }) => {
    return (
        <>
            <div className="col-sm-3 col-md-3 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Financial Institution</label>
                <Field name= { prefix+"financialInstitution"}>
                    {({ field }) => (
                        <InputField 
                            fieldName = {prefix+"financialInstitution"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"financialInstitution").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"financialInstitution") } </div> : "" }
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Credit Limit</label>
                <Field name= { prefix+"creditLimit"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"creditLimit"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"creditLimit").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"creditLimit") } </div> : "" }
            </div>             
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Balance Remaining</label>
                <Field name= { prefix+"balanceRemaining"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"balanceRemaining"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"balanceRemaining").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"balanceRemaining") } </div> : "" }
            </div> 
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Rate</label>
                <Field name= { prefix+"rate"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"rate"} 
                            fieldValue = {field.value !== undefined ? field.value : ""} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"rate").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"rate") } </div> : "" }
            </div>             
        </>
    )
}

export default Overdraft;