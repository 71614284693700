
import Error404 from "./Error404";
import Error403 from "./Error403";
import Error500 from "./Error500";
import ErrorDefault from './ErrorDefault';


const DecideErrorPage = ({ errorCode , message}) => {
    switch (errorCode) {
        case 404:
            return <Error404  message={message} />
            break;
        case 403:
            return <Error403  message={message} />
            break;
        case 500:
            return <Error500 message={message} />
            break;
        default:
            return <ErrorDefault message={message} />;
            break;
    }
}

export default DecideErrorPage;