 

import * as Yup from 'yup';

  // Define separate schemas for each income type

 

  const categorySchemas = {
    category1: Yup.object().shape({
        debtType: Yup.string().required('Required'),
        financialInstitution: Yup.string().required('Required'),
        assetValue: Yup.number().required('Required').min(1, 'Must be more than 0'),
        amountBorrowed: Yup.number().required('Required').min(1, 'Must be more than 0'),
        balanceRemaining: Yup.number().required('Required').min(1, 'Must be more than 0'),
        repaymentAmount: Yup.number().required('Required').min(1, 'Must be more than 0'),
        repaymentFrequency: Yup.string().required('Required'), 
        //startDate: Yup.string().required('Required').matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY) e.g 01/2024'),
        startDate: Yup.string().notRequired().matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY) e.g 01/2024'),
        //term: Yup.number().required('Required').min(1, 'Must be more than 0'),
        //rate: Yup.number().required('Required').min(1, 'Must be more than 0'),
         
         
    }),
    category2: Yup.object().shape({
        debtType: Yup.string().required('Required'),
        financialInstitution: Yup.string().required('Required'),
        assetValue: Yup.number().required('Required').min(1, 'Must be more than 0'),
        amountBorrowed: Yup.number().required('Required').min(1, 'Must be more than 0'),
        balanceRemaining: Yup.number().required('Required').min(1, 'Must be more than 0'),
        applicantShareOfTotalMonthlyRepayment: Yup.number().required('Required').min(1, 'Must be more than 0'),
        totalMonthlyRepaymentAmount: Yup.number().required('Required').min(1, 'Must be more than 0'),
        repaymentFrequency: Yup.string().required('Required'), 
        //startDate: Yup.string().required('Required').matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY) e.g 01/2024'),
        startDate: Yup.string().notRequired().matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY) e.g 01/2024'),
        //term: Yup.number().required('Required').min(1, 'Must be more than 0'),
        //rate: Yup.number().required('Required').min(1, 'Must be more than 0'),
    }), 
    category3: Yup.object().shape({
        debtType: Yup.string().required('Required'),
        financialInstitution: Yup.string().required('Required'),
        creditLimit: Yup.number().required('Required').min(1, 'Must be more than 0'),
        balanceRemaining: Yup.number().required('Required').min(1, 'Must be more than 0'),
        repaymentAmount : Yup.number().required('Required').min(1, 'Must be more than 0'),
        //amountBorrowed: Yup.number().required('Required').min(1, 'Must be more than 0'),
        repaymentFrequency: Yup.string().required('Required'), 
        //startDate: Yup.string().required('Required').matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY) e.g 01/2024'),
        startDate: Yup.string().notRequired().matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY) e.g 01/2024'),
    }),
    category4: Yup.object().shape({
        debtType: Yup.string().required('Required'),
        financialInstitution: Yup.string().required('Required'),
        creditLimit: Yup.number().required('Required').min(1, 'Must be more than 0'),
        amountBorrowed: Yup.number().required('Required').min(1, 'Must be more than 0'),
        balanceRemaining: Yup.number().required('Required').min(1, 'Must be more than 0'), 
        repaymentAmount : Yup.number().required('Required').min(1, 'Must be more than 0'),
        repaymentFrequency: Yup.string().required('Required'),   
        //startDate: Yup.string().required('Required').matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY) e.g 01/2024'),
        startDate: Yup.string().notRequired().matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Invalid date format (MM/YYYY) e.g 01/2024'),
    }),
    category5: Yup.object().shape({
        debtType: Yup.string().required('Required'),
        financialInstitution: Yup.string().required('Required'),
        creditLimit: Yup.number().required('Required').min(1, 'Must be more than 0'),
        balanceRemaining: Yup.number().required('Required').min(1, 'Must be more than 0'), 
        //rate: Yup.number().required('Required').min(1, 'Must be more than 0'),
    }),
    category6: Yup.object().shape({
        debtType: Yup.string().required('Required'),
        financialInstitution: Yup.string().required('Required'),
        amountBorrowed: Yup.number().required('Required').min(1, 'Must be more than 0'),
        balanceRemaining: Yup.number().required('Required').min(1, 'Must be more than 0'), 
        repaymentAmount : Yup.number().required('Required').min(1, 'Must be more than 0'),
        repaymentFrequency: Yup.string().required('Required')
    }),
  };

  const debtsEntrySchema = (values) => {
    let category = '';
    switch(true){
        case [ "personal" , "car" , "motorBike" , "boat" , "caravan" , "jetSki" , "truck", "investmentProperties" , "hirePurchase"].includes(values.debtType):
            category = "category1";    
        break;
        case ["house" , "land" , "land" , "mortgage"  ].includes(values.debtType):
            category = "category2";
            break;    
        case ["creditCard" , "storeCard" , "chargeCard"].includes(values.debtType):
            category = "category3";
            break;    
        case ["buyNowPayLater" ].includes(values.debtType):
            category = "category4";
            break;    
        case ["overdraft" ].includes(values.debtType):
            category = "category5";
            break;    
        case ["other"].includes(values.debtType):
            category = "category6";
            break;    
         default:
            category = values.debtType;
            break;
    }

    console.log("category", category);
    const schema = categorySchemas[category] || Yup.object(); // Default to an empty schema if income type not found
    return schema;
  };
  

// Define the validation schema for the income object
const DebtsValidationSchema = Yup.object().shape({
    debts:  Yup.array().of(Yup.lazy(debtsEntrySchema))
});

export default DebtsValidationSchema;
