import { useEffect, useState, useRef } from "react";
import { DownArrowIcon } from '../../../../components/HtmlComponents/Icons';
import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal, RadioGroup, InputGroup, InputField, RightDrawer } from '../../../../components/HtmlComponents';
import { formatDate } from '../../../../helpers';
import { Formik, Form, Field, useField, useFormikContext , ErrorMessage } from 'formik';
import BuyingMotorVehicleItem from "./components/BuyingMotorVehicleItem";
import TradeIn from "./components/TradeIn";

import {  } from "../../../../api";

import Constants from "../../../../components/global/Constants";
import { capitalizeFirst } from '../../../../helpers';
 
import Notifications from "../../../../components/global/Notifications";

import QuoteValidationSchema from "./schema/QuoteValidationSchema";

const Quote = ({ initialValues, formRef, executeApplicationSubmissionHandler, openByDefault }) => {
    const [isOpen, setIsOpen] = useState(openByDefault == true ? "block" : "none");
    const [loading, setLoading] = useState(true);
    const [drawerSettings, setDrawerSettings] = useState({ visible: false, heading: "", childName: "", meta: {} });

    const [loanPurposes, setLoanPurposes] = useState({});
    const [loanTypes, setLoanTypes] = useState({});
    const [applicationCategories, setApplicationCategories] = useState([]);

    const [referrerGroups, setReferrerGroups] = useState([]);
    const [referrers, setReferrers] = useState([]);
    const [referrerUsers, setReferrerUsers] = useState([]);
    const [lenders, setLenders] = useState([]);
    /***INSURANCE STATES START  */
    const insuranceLabels = { "comprehensive": "Comprehensive", "cci": "CCI", "warrenty": "Warranty", "gap": "GAP", "lti": "LTI", "rti": "RTI" }
    const [insuranceCompanies, setInsuranceCompanies] = useState([]);

    /** INSURANCE STATES END */


    /***** REFERRER GROUPS LIST  ******/
    

    useEffect(() => {
        //LOAD LOAN PURPOSES
        var loanPurposeOptions = [];
        Object.keys(Constants.LOAN_PURPOSES).map(function (key, index) {
            loanPurposeOptions.push({ value: key, label: Constants.LOAN_PURPOSES[key] })
        })
        setLoanPurposes(loanPurposeOptions);

        //LOAD LOAN TYPES
        var loanTypeoptions = [];
        Constants.LOAN_TYPE.map(item => {
            loanTypeoptions.push({ value: item, label: capitalizeFirst(item) });
        })
        setLoanTypes(loanTypeoptions);

        //APPLICATION CATEGORIES 
        var applicationCategoriesOptions = [];
        Object.keys(Constants.APPLICATION_CATEGORY).map(itemIndex => {
            applicationCategoriesOptions.push({ value: itemIndex, label: Constants.APPLICATION_CATEGORY[itemIndex] });
        })
        setApplicationCategories(applicationCategoriesOptions);


        setLoading(false);
    }, []);


    //REPAYMENT CALCULATION 
    const RepaymentObserve = (props) => {
        const {
            values: { loan: { totalFinancedAmountIncludingInsurances, totalFinancedAmountExcludingInsurances, loanTerm, interestRate, balloon: { balloonAmount } }, repayments: { repaymentType } },
            touched,
            setFieldValue,
        } = useFormikContext();
        const [field, meta] = useField(props);
        useEffect(() => {
            calculateRepayments(setFieldValue);

        }, [totalFinancedAmountIncludingInsurances, totalFinancedAmountExcludingInsurances, loanTerm, interestRate, balloonAmount, repaymentType,
            touched.totalFinancedAmountIncludingInsurances, touched.totalFinancedAmountExcludingInsurances, touched.loanTerm, touched.interestRate, touched.balloonAmount, touched.repaymentType, setFieldValue, props.name]);
        return ("");

    }
    const calculateRepayments = async (setFieldValue) => {
        var currentValues = formRef !== undefined && formRef.current !== undefined && formRef.current !== null && formRef.current.values !== undefined && formRef.current.values !== null ? formRef.current.values : {}
        let loan = currentValues !== undefined && currentValues.loan !== undefined ? currentValues.loan : {};

        let totalFinancedAmountExcludingInsurances = currentValues.loan !== undefined && loan.totalFinancedAmountExcludingInsurances !== undefined ? loan.totalFinancedAmountExcludingInsurances : 0;
        let totalFinancedAmountIncludingInsurances = loan !== undefined && loan.totalFinancedAmountIncludingInsurances !== undefined ? loan.totalFinancedAmountIncludingInsurances : 0;
        let interestRate = loan !== undefined && loan.interestRate !== undefined ? loan.interestRate : 0;
        interestRate = interestRate / 100;
        interestRate = interestRate / 12;

        let loanTerm = loan !== undefined && loan.loanTerm !== undefined ? loan.loanTerm : 0;
        let balloonAmount = loan !== undefined && loan.balloon !== undefined && loan.balloon.balloonAmount !== undefined ? loan.balloon.balloonAmount : 0;
        let repaymentType = currentValues !== undefined && currentValues.repayments !== undefined && currentValues.repayments.repaymentType ? currentValues.repayments.repaymentType : "";

        //CALCULATE PAYMENT - WITH INSURANCE
        let monthlyRepaymentIncludingInsurance = await PMT(interestRate, loanTerm, -totalFinancedAmountIncludingInsurances, balloonAmount, repaymentType);
        setFieldValue("repayments.includingInsurance.weekly", ((monthlyRepaymentIncludingInsurance * 12) / 52).toFixed(2))
        setFieldValue("repayments.includingInsurance.fortnightly", ((monthlyRepaymentIncludingInsurance * 12) / 26).toFixed(2))
        setFieldValue("repayments.includingInsurance.monthly", (monthlyRepaymentIncludingInsurance).toFixed(2))


        //CALCULATE PAYMENT WITHOUT INSURANCES
        let monthlyRepaymentExcludingInsurance = await PMT(interestRate, loanTerm, -totalFinancedAmountExcludingInsurances, balloonAmount, repaymentType);
        setFieldValue("repayments.excludingInsurance.weekly", ((monthlyRepaymentExcludingInsurance * 12) / 52).toFixed(2))
        setFieldValue("repayments.excludingInsurance.fortnightly", ((monthlyRepaymentExcludingInsurance * 12) / 26).toFixed(2))
        setFieldValue("repayments.excludingInsurance.monthly", (monthlyRepaymentExcludingInsurance).toFixed(2))


    }
    const PMT = async (interestRate, loanTerm, present_value, balloonAmount, repaymentType) => {
        try {
            // console.log("balloonAmount form PMT ::::::::::::::::::::::  ", balloonAmount)
            var when = repaymentType == "arrears" ? 0 : 1;  // 
            if (interestRate == 0) { return - (parseFloat(balloonAmount) + parseFloat(present_value)) / loanTerm; }
            else { return - (parseFloat(balloonAmount) + parseFloat((present_value * Math.pow((1 + parseFloat(interestRate)), loanTerm)))) / ((1 + parseFloat(interestRate * when)) / interestRate * (Math.pow((1 + parseFloat(interestRate)), loanTerm) - 1)); }
        }
        catch (e) { return 0; }
    }

    //UTILITIES HANDLER - DRAW CLOSE/OPEN , UPDATE VALUE GOT FROM CHILD COMPONENTS LIKE SEARCH CAR INFO
    const utilitiesHandler = (action, payload) => {
        switch (action) {
            case "openSearchForCarInfoWindow":
                setDrawerSettings({ visible: true, heading: "Search Car Info ", childName: "searchForCarInfo", meta: {} });
                break;
            case "carInfoReceived":
                initialValues.buyingItem.motorVehicles[0] = payload;
                var formrefValues = formRef !== null && formRef !== undefined && formRef.current !== null && formRef.current !== undefined && formRef.current.values !== null && formRef.current.values !== undefined ? formRef.current.values : {};
                utilitiesHandler("closeDrawer", null);
                break;
            //LIST OF ACTIONS COMES UNDER COMPLIANCES
            case "closeDrawer": setDrawerSettings({ visible: false });
                break;
        }
    }

    return (
        <>
            <div className="card mb-5 mb-xxl-8" key="quoteBlock">
                <div className="card-body card-body pt-6 pb-6">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <h3 className="pb-0 mb-0">Quote</h3>
                    </div>

                    <div>
                        <div className="separator separator-dashed my-5"></div>
                        <Formik
                            validationSchema={QuoteValidationSchema}
                            initialValues={initialValues}
                            innerRef={formRef}
                            enableReinitialize={true}
                            //validateOnBlur={false}
                            validateOnChange={true}
                            validateOnBlur={true}
                            onSubmit={values => {
                                var tempValues = JSON.parse(JSON.stringify(values));
                                //CEHCK IF REFERRER GROUP IS SELECTED OR NOT - IF RG NOT SELECTED THEN WHOW ERROR
                                tempValues["insurances"] = [];

                                executeApplicationSubmissionHandler("quote", tempValues);
                            }}
                        >
                            {({ errors, touched, setFieldValue, values }) => (
                                <Form>
                                    <div className="row mb-5">                                         
                                        <div className="col-md-7 fv-row fv-plugins-icon-container">

                                            <div className="row mb-5">
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Purpose</label>
                                                    <Field name="loan.purpose" style={{color: "red", border: "1px solid red"}}>
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="loan.purpose"
                                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                                options={loanPurposes}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                     {errors.loan && errors.loan.purpose ? <div className="error"> {errors.loan.purpose} </div> : "" } 
                                                </div>

                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Loan Type</label>
                                                    <Field name="loan.type">
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="loan.type"
                                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                                options={loanTypes}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.loan && errors.loan.type ? <div className="error"> {errors.loan.type} </div> : "" } 
                                                </div>
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Loan For</label>
                                                    <Field name="category">
                                                        {({ field }) => (
                                                            <SelectField
                                                                fieldName="category"
                                                                defaultSelectedOption={{ value: field.value, label: '' }}
                                                                options={applicationCategories}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.loan && errors.loan.category ? <div className="error"> {errors.loan.category} </div> : "" } 
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Total Asset Price</label>
                                                    <Field name="financial.assetPrice" className="errorBorder" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.assetPrice"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.financial && errors.financial.assetPrice  ? <div  className="error"> {errors.financial.assetPrice} </div> : "" }
                                                </div>
                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Cash Deposit</label>
                                                    <Field name="financial.cashDeposit" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="financial.cashDeposit"
                                                                symbol="dollar"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.financial && errors.financial.cashDeposit  ? <div  className="error"> {errors.financial.cashDeposit} </div> : "" }
                                                </div>
                                            </div>

                                            <TradeIn errors={errors} touched={touched} setFieldValue={setFieldValue} values={values} />

                                        </div>
                                        <div className="col-md-1 fv-row fv-plugins-icon-container"></div>


                                        <div className="col-md-4 fv-row fv-plugins-icon-container">
                                            <div className="row mb-5">
                                                <div className="col-sm-12 col-md-6 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Loan Term</label>
                                                    <Field name="loan.loanTerm" >
                                                        {({ field }) => (
                                                            <InputGroup
                                                                fieldName="loan.loanTerm"
                                                                symbolType="custom"
                                                                symbol="Months"
                                                                symbolPosition="right"
                                                                placeholder="0"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.loan && errors.loan.loanTerm  ? <div  className="error"> {errors.loan.loanTerm} </div> : "" }
                                                </div>
                                                <div className="col-sm-12 col-md-6 fv-row fv-plugins-icon-container">
                                                    <label className="required fs-8 fw-bold mb-2">Customer Weekly Budget</label>
                                                    <Field name="loan.customerWeeklyBudget" >
                                                        {({ field }) => (
                                                            <FloatField
                                                                fieldName="loan.customerWeeklyBudget"
                                                                symbolType="custom"
                                                                symbol="$"
                                                                symbolPosition="left"
                                                                placeholder="0.00"
                                                                fieldValue={field.value}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                        )}
                                                    </Field>
                                                    {errors.loan && errors.loan.customerWeeklyBudget  ? <div  className="error"> {errors.loan.customerWeeklyBudget} </div> : "" }
                                                </div>


                                                <div className="col-md-10 fv-row fv-plugins-icon-container">
                                                    <div className="row">
                                                        <div className="col-sm-12"> <label className="required fs-8 fw-bold mb-2">Balloon</label></div>
                                                        <div className="col-md-6">
                                                            <Field name="loan.balloon.balloonPercentage" >
                                                                {({ field }) => (
                                                                    <FloatField
                                                                        fieldName="loan.balloon.balloonPercentage"
                                                                        symbolType="custom"
                                                                        symbol="%"
                                                                        symbolPosition="right"
                                                                        placeholder="Balloon %"
                                                                        fieldValue={field.value}
                                                                        setFieldValue={(name, value) => {
                                                                            setFieldValue("loan.balloon.balloonPercentage", value);
                                                                            var tmpAssetPrice = values.financial.assetPrice;
                                                                            if(isNaN(parseFloat(tmpAssetPrice))){ tmpAssetPrice = 0; value = 0;}
                                                                            var tmpBalloonAmount = ((tmpAssetPrice * value) / 100).toFixed(2);

                                                                            if(isNaN(parseFloat(tmpBalloonAmount))){ tmpBalloonAmount = 0.00;}
                                                                            setFieldValue("loan.balloon.balloonAmount", tmpBalloonAmount);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors.loan && errors.loan.balloon && errors.loan.balloon.balloonPercentage  ? <div  className="error"> {errors.loan.balloon.balloonPercentage} </div> : "" }
                                                        </div>
                                                        <div className="col-md-6">
                                                            <Field name="loan.balloon.balloonAmount" >
                                                                {({ field }) => (
                                                                    <FloatField
                                                                        fieldName="loan.balloon.balloonAmount"
                                                                        symbolType="dollar"
                                                                        symbol="%"
                                                                        symbolPosition="right"
                                                                        placeholder="Balloon Amount"
                                                                        fieldValue={field.value}
                                                                        setFieldValue={(name, value) => {
                                                                            setFieldValue("loan.balloon.balloonAmount", value);
                                                                            var tmpAssetPrice = values.financial.assetPrice;
                                                                            if(isNaN(parseFloat(tmpAssetPrice))){ tmpAssetPrice = 0; value = 0;}
                                                                            var tmpBalloonPercentage = ((value / tmpAssetPrice) * 100).toFixed(2);

                                                                            if(isNaN(parseFloat(tmpBalloonPercentage))){ tmpBalloonPercentage = 0.00;}
                                                                            setFieldValue("loan.balloon.balloonPercentage", tmpBalloonPercentage);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors.loan && errors.loan.balloon && errors.loan.balloon.balloonAmount  ? <div  className="error"> {errors.loan.balloon.balloonAmount} </div> : "" }                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <BuyingMotorVehicleItem errors={errors} touched={touched} setFieldValue={setFieldValue} lookupCarInfo={() => { utilitiesHandler("openSearchForCarInfoWindow", {}) }} />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        {Object.keys(errors).length > 0 ?
                                            <FormSubmissionButton isActive={false} label="Save & Continue" /> :
                                            <FormSubmissionButton isActive={true} label="Save & Continue" />
                                        }
                                    </div> 
                                            
                                    {/** row end **/}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Quote;