//getAccessTokenPermissions
import axios from './axios';


const getAccessTokenPermissions = async(token, applicationId, applicantId) => {
    try{
        const response = await axios.get(`public/accessTokenPermissions/${applicationId}/${applicantId}` , { headers: { Authorization: "Bearer " + token,  "api-key" : process.env.REACT_APP_API_KEY} });
        return {"error" : 0 , httpCode: response.status, "message" : "", data: response.data }
    }
    catch(e){
        if (e.response) {
            throw new Error(e.response.data["message"] ? e.response.data["message"] : e.response.data)
         }
    }
}

export {getAccessTokenPermissions}