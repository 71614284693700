import { FormSubmissionButton, CustomButton, SelectField, InputField, Modal, FloatField, IconButton } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import Constants from '../../../../../components/global/Constants';
import AddressForm from '../../../../../components/global/AddressForm';

//import { performApplicationAdditionalActions } from '../../../../../api/';
import { formatDate, removeSpace } from "../../../../../helpers";
import Notifications from '../../../../../components/global/Notifications';


const SelfIncome = ({ prefix, setFieldValue, frequencyList, applicationId , errors}) => {
    const [addressModal, setAddressModal] = useState({ visible: false, fieldName: "", callback: '' });
    const [organisationNameList, setOrganisationNameList] = useState([]);
    const [companyNameList, setCompanyNameList] = useState([]);
    const [statesList, setStateList] = useState([]);
    const [postCodeList, setPostCodeList] = useState();

    const [abn, setAbn] = useState();
    const [acn, setAcn] = useState();


    const FetchStates = () => {
        var options = [];
        Object.keys(Constants.STATES).map(key => {
            options.push({ value: key, label: Constants.STATES[key] });
        });
        setStateList(options);
    }
    useEffect(() => {
        FetchStates();

    }, [])


    const abnLookup = async () => {
        //GENERATE ABN REPORT AND GET THE JSON DATA
        /*if (removeSpace(abn).length !== 11) {
            Notifications("error", "Please provide ABN (11 charcters) ")
        } else {
            var response = await performApplicationAdditionalActions(token, "abnLookup", applicationId, { abn: abn });
            if (response["error"] !== undefined && response["error"] == 0) {
                var payload = response["data"] !== undefined && response["data"]["payload"] !== undefined ? response["data"]["payload"] : {};
                fillDataUponLookup(payload);
            }
        }*/
    }

    const acnLookup = async () => {
        /*if (removeSpace(acn).length !== 9) {
            Notifications("error", "Please provide ACN (9 charcters) ")
        } else {
            var response = await performApplicationAdditionalActions(token, "acnLookup", applicationId, { acn: acn });
            if (response["error"] !== undefined && response["error"] == 0) {
                var payload = response["data"] !== undefined && response["data"]["payload"] !== undefined ? response["data"]["payload"] : {};
                fillDataUponLookup(payload);
            }
        }*/


    }

    //FILL DATA UPON LOOKUP ABN | ACN
    const fillDataUponLookup = (payload) => {
        //entityType
        var entityType = payload["entityType"] !== undefined ? payload["entityType"] : "";
        setFieldValue(prefix + "businessType", entityType);

        //entityStatusCode
        var entityStatusCode = payload["entityStatusCode"] !== undefined && payload["entityStatusCode"][0] ? payload["entityStatusCode"][0] : "";
        setFieldValue(prefix + "status", entityStatusCode);


        //POSTAL CODE - STATE
        var addresses = payload["mainBusinessPhysicalAddress"] !== undefined && Array.isArray(payload["mainBusinessPhysicalAddress"]) ? payload["mainBusinessPhysicalAddress"] : [];
        if (addresses.length > 0) {
            var tmpPostCodes = [];
            for (var i = 0; i < addresses.length; i++) {
                var tmpName = addresses[i].postcode !== undefined && addresses[i].postcode !== undefined ? addresses[i].postcode : "";
                if (tmpName !== "") { tmpPostCodes.push({ value: tmpName, label: tmpName }) }
            }
            setPostCodeList(tmpPostCodes);
        } else {

        }

        //STATE
        var state = payload["mainBusinessPhysicalAddress"] !== undefined && payload["mainBusinessPhysicalAddress"]["state"] !== undefined ? payload["mainBusinessPhysicalAddress"]["state"] : "";
        if (state !== "") { setFieldValue(prefix + "state", state); }

        //EFFECTIVE SINCE
        var effectiveSince = payload["effectiveFrom"] !== undefined ? formatDate(payload["effectiveFrom"], "YYYY-MM-DD", "DD/MM/YYYY") : "";
        setFieldValue(prefix + "effectiveSince", effectiveSince);

        //gst registered  - goodsAndServicesTax
        var entityStatusCode = payload["goodsAndServicesTax"] !== undefined ? formatDate(payload["goodsAndServicesTax"], "DD MMM YYYY", "DD/MM/YYYY") : "";
        setFieldValue(prefix + "gstRegisteredSince", entityStatusCode);

        //ORGANISATION NAMES -> BUSINESS NAMES 
        var businessNames = payload["businessName"] != undefined && Array.isArray(payload["businessName"]) ? payload["businessName"] : [];
        var tmpOrganisationNames = [];
        for (var i = 0; i < businessNames.length; i++) {
            var tmpName = businessNames[i].organisationName !== undefined && businessNames[i].organisationName[0] !== undefined ? businessNames[i].organisationName[0] : "";
            if (tmpName !== "") { tmpOrganisationNames.push({ value: tmpName, label: tmpName }) }
        }
        setOrganisationNameList(tmpOrganisationNames);


        //COMPANY NAME
        var mainNames = payload["mainName"] != undefined && Array.isArray(payload["mainName"]) ? payload["mainName"] : [];
        var tmpCompanyNames = [];
        for (var i = 0; i < mainNames.length; i++) {
            var tmpName = mainNames[i].organisationName !== undefined && mainNames[i].organisationName[0] !== undefined ? mainNames[i].organisationName[0] : "";
            if (tmpName !== "") { tmpCompanyNames.push({ value: tmpName, label: tmpName }) }
        }
        setCompanyNameList(tmpCompanyNames);
    }

    return (
        <>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">ABN <IconButton buttonOnClick={abnLookup} iconType="search" buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "orange" }} iconColor="orange" /></label>
                <Field name={prefix + "abn"} className="form-control" style={{ height: "35px", fontWeight: "400" }}
                    onChange={(e) => {
                        setFieldValue(prefix + "abn", e.target.value);
                        setAbn(e.target.value);
                    }}
                />
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">ACN <IconButton buttonOnClick={acnLookup} iconType="search" buttonStyle={{ border: "0px", backgroundColor: "transparent", color: "orange"  }} iconColor="orange" /></label>
                <Field name={prefix + "acn"} className="form-control" style={{ height: "35px", fontWeight: "400" }}
                    onChange={(e) => {
                        setFieldValue(prefix + "acn", e.target.value);
                        setAcn(e.target.value);
                    }}
                />
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Business Name</label>
                <Field name={prefix + "businessName"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "businessName"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>


            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Organisation Name</label>
                <Field name={prefix + "organisationName"}>
                    {({ field }) => (
                        <input
                            type="text"
                            list={prefix + "organisationName"}
                            className="form-control"
                            style={{ height: "35px", fontWeight: "400" }}
                            value={field.value}
                            onChange={(e) => {
                                setFieldValue(prefix + "organisationName", e.target.value)
                            }}
                        />
                    )}
                </Field>

                <datalist id={prefix + "organisationName"}>
                    {
                        Array.isArray(organisationNameList) && organisationNameList.length > 0 ? organisationNameList.map((item, key) =>
                            <option key={key} value={item.label} />
                        ) : <option key={0} value={"Select"} />
                    }
                </datalist>
            </div>


            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Company Name</label>
                <Field name={prefix + "companyName"}>
                    {({ field }) => (
                        <input
                            type="text"
                            list={prefix + "companyName"}
                            className="form-control"
                            style={{ height: "35px", fontWeight: "400" }}
                            value={field.value}
                            onChange={(e) => {
                                setFieldValue(prefix + "companyName", e.target.value)
                            }}
                        />
                    )}
                </Field>

                <datalist id={prefix + "companyName"}>
                    {
                        Array.isArray(companyNameList) && companyNameList.length > 0 ? companyNameList.map((item, key) =>
                            <option key={key} value={item.label} />
                        ) : <option key={0} value={"Select"} />
                    }
                </datalist>
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Business Type</label>
                <Field name={prefix + "businessType"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "businessType"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Status</label>
                <Field name={prefix + "status"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "status"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">State</label>
                <Field name={prefix + "state"}>
                    {({ field }) => (
                        <SelectField
                            fieldName={prefix + "state"}
                            defaultSelectedOption={{ value: field.value, label: "" }}
                            options={statesList}
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Post Code</label>
                <Field name={prefix + "postCode"}>
                    {({ field }) => (
                        <input
                            type="text"
                            list={prefix + "postCode"}
                            className="form-control"
                            style={{ height: "35px", fontWeight: "400" }}
                            value={field.value}
                            onChange={(e) => {
                                setFieldValue(prefix + "postCode", e.target.value)
                            }}
                        />
                    )}
                </Field>

                <datalist id={prefix + "postCode"}>
                    {
                        Array.isArray(postCodeList) && postCodeList.length > 0 ? postCodeList.map((item, key) =>
                            <option key={key} value={item.label} />
                        ) : <option key={0} value={"Select"} />
                    }
                </datalist>
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Effective Since</label>
                <Field name={prefix + "effectiveSince"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "effectiveSince"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">GST Registered Since</label>
                <Field name={prefix + "gstRegisteredSince"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "gstRegisteredSince"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Industry Type</label>
                <Field name={prefix + "industryType"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "industryType"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            <div className="col-sm-3 col-md-4 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Business Trading Address</label>
                <Field name={prefix + "bunsinessTradingAddress"}>
                    {({ field }) => (
                        <input
                            className="form-control"
                            style={{ height: Constants.TEXTFIELD_HEIGHT }}
                            type="text"
                            value={field.value && field.value.fullAddress ? field.value.fullAddress : ""}
                            onClick={() => setAddressModal({ visible: true, fieldName: `${prefix}bunsinessTradingAddress`, callback: setFieldValue })}
                            onChange={(e) => { }}
                        />
                    )}
                </Field>
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Accountant Name</label>
                <Field name={prefix + "accountantName"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "accountantName"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Accountant #</label>
                <Field name={prefix + "accountantContactNumber"}>
                    {({ field }) => (
                        <InputField
                            fieldName={prefix + "accountantContactNumber"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>
            
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Yearly Gross Income</label>
                <Field name={prefix + "yearlyGrossIncome"}>
                    {({ field }) => (
                        <FloatField
                            fieldName={prefix + "yearlyGrossIncome"}
                            fieldValue={field.value}
                            placeholder=""
                            setFieldValue={setFieldValue}
                        />
                    )}
                </Field>
            </div>

            {addressModal.visible === true ?
                <Modal modalVisibility={setAddressModal} heading="Creditrep Address">
                    <AddressForm
                        fieldName={addressModal.fieldName}
                        closeButtonHandler={() => setAddressModal({ visible: false, fieldName: "", callback: '' })}
                        addressHandler={addressModal.callback}
                    />
                </Modal>
                : ""}

        </>
    )
}

export default SelfIncome;