import { useState, useEffect } from 'react';
import { FormSubmissionButton, TextEditor, CheckBox, FloatField, SelectField, Modal, RadioGroup, InputGroup, InputField, CustomButton } from '../../../components/HtmlComponents';

const ApplicationSubmissionPage = ({ callback, applicationErrors }) => {

    const [submissionAlowed, setSubmissionAlowed] = useState(true);
    const [formattedErrors, setFormattedErrors] = useState({
        "quote": [],
        "personalDetail": [],
        "income": [],
        "assets": [],
        "debts": [],
        "expenses": [],
    });

    //CHECK IF THERE IS NOT ERROR THEN ENABLE THE SUBMISSION ALLOWED STATE OTHERWISE GRAY IT OUT
    useEffect(() => {

        //APPLICATION ERRORS IS AN OBJECT THAT HAS QUOTE, PERSONALdETAILS, ASSETS, DEBTS, INCOME, EXPENSES KEYS AND THEY HAVE ERRORS ARRAY
        //CHECK IF ALL THE ERRORS ARRAY ARE EMPTY THEN ENABLE THE SUBMISSION BUTTON
        console.log("applicationErrors", applicationErrors)
        let errors = applicationErrors;
         
        checkNestedErrors("quote", errors.quote); //CHECK IF THERE ARE ANY ERRORS IN QUOTE
        checkNestedErrors("personalDetail", errors.personalDetail); //CHECK IF THERE ARE ANY ERRORS IN PERSONAL DETAILS
        checkNestedErrors("income", errors.income); //CHECK IF THERE ARE ANY ERRORS IN INCOME
        checkNestedErrors("assets", errors.assets); //CHECK IF THERE ARE ANY ERRORS IN ASSETS
        checkNestedErrors("debts", errors.debts); //CHECK IF THERE ARE ANY ERRORS IN DEBTS
        checkNestedErrors("expenses", errors.expenses); //CHECK IF THERE ARE ANY ERRORS IN EXPENSES
    }, []);


    const checkNestedErrors = (moduleName, errors) => {
        let hasNestedErrors = false;
        let errorsArray = [];

        // Helper function to recursively process nested errors
        const processErrors = (obj) => {
            for (let key in obj) {
                if (Array.isArray(obj[key])) {
                    for (let i = 0; i < obj[key].length; i++) {
                        processErrors(obj[key][i]);
                    }
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    processErrors(obj[key]);
                } else {
                    hasNestedErrors = true;
                    errorsArray.push(obj[key]);
                }
            }
        };

        setFormattedErrors( prevValues  => { return { ...prevValues, [moduleName]: errorsArray } });
        if(errorsArray.length > 0){setSubmissionAlowed(false);}  //IF ERROR EXISTS THEN DISABLE THE SUBMISSION BUTTON

        processErrors(errors);
        console.log("errorsArray -------------- ", errorsArray);
        return { hasNestedErrors, errorsArray };
    };

    //PRINT ERRORS 
    const ListErrors = ({ moduleName }) => {
        var moduleLabel = '';
        if (moduleName === "quote") {
            moduleLabel = "Quote";
        } else if (moduleName === "personalDetail") {
            moduleLabel = "Personal Details";
        } else if (moduleName === "income") {
            moduleLabel = "Income";
        } else if (moduleName === "assets") {
            moduleLabel = "Assets";
        } else if (moduleName === "debts") {
            moduleLabel = "Debts";
        } else if (moduleName === "expenses") {
            moduleLabel = "Expenses";
        }

        return (
            <div className="alert alert-danger" role="alert">
                <label>{moduleLabel}</label>
                <ul>
                    {
                        formattedErrors[moduleName].map((error, index) => {
                            return (
                                <li key={index}>{error}</li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }

    return (
        <div className="card mb-5 mt-5 mb-xxl-8" key="expensesBlock">
            <div className="card-body card-body pt-6 pb-6">
                <div className=" ">
                    <h3 className="pb-0 mb-0">Congratulations! You're about to submit your application to NFAL. Before you proceed, please ensure that you have provided all the necessary details. </h3>
                    <p className="pb-0 mt-3 mb-0">Remember, providing maximum details can significantly expedite the processing and approval of your application. The more information we have, the quicker we can assess your request.
                        Thank you for your attention to detail, and best of luck with your application!</p>
                </div>

                { formattedErrors.quote.length > 0 ? <ListErrors moduleName="quote" /> :"" }
                { formattedErrors.personalDetail.length > 0 ? <ListErrors moduleName="personalDetail" /> :"" }
                { formattedErrors.income.length > 0 ? <ListErrors moduleName="income" /> :"" }
                { formattedErrors.assets.length > 0 ? <ListErrors moduleName="assets" /> :"" }
                { formattedErrors.debts.length > 0 ? <ListErrors moduleName="debts" /> :"" }
                { formattedErrors.expenses.length > 0 ? <ListErrors moduleName="expenses" /> :"" }
                
                <div className="row g-4 g-xl-5 mt-6">
                    <div className="col-12 col-md-12">

{
    !submissionAlowed ? <div className="alert alert-danger" role="alert">Please fix the errors first </div> : ""
}
                        <CustomButton
                            buttonClassName="btn-primary"
                            isActive={ submissionAlowed ? true : false}
                            buttonStyle={{ float: "right", marginLeft: "10px" }}
                            buttonOnClick={() => {
                                callback("submitApplication");
                            }}
                            buttonText="Submit Application"
                        />

                        <CustomButton
                            buttonClassName="btn-primary"
                            isActive={true}
                            buttonStyle={{ float: "right", backgroundColor: "#f0f0f0", color: "#000" }}
                            buttonOnClick={() => {
                                callback("goBackToQuote");
                            }}
                            buttonText="Go Back"
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationSubmissionPage;