 

import { FormSubmissionButton, CustomButton, SelectField, InputField, FloatField } from '../../../../../components/HtmlComponents';
import { Formik, Form, Field, useField, useFormikContext } from 'formik';
import { readNestedErrorByPath } from '../../../../../helpers';

const HomeDuties = ({prefix , setFieldValue, errors  }) => {
    return (
        <>
            <div className="col-sm-3 col-md-2 mb-5 fv-row fv-plugins-icon-container" >
                <label className="  fs-8 fw-bold mb-2">Months</label>
                <Field name= { prefix+"duration"}>
                    {({ field }) => (
                        <FloatField 
                            fieldName = {prefix+"duration"} 
                            fieldValue = {field.value} 
                            placeholder = "" 
                            setFieldValue = {setFieldValue}
                        />
                    )}
                </Field>
                { readNestedErrorByPath(errors, prefix+"duration").length > 0 ? <div className="error"> { readNestedErrorByPath(errors, prefix+"duration") } </div> : "" }
            </div>                      
        </>
    )
}

export default HomeDuties;