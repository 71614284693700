import Cookies from 'js-cookie';

export const setCookie = (name, value, options = {}) => {
    console.log("Setting cookie: ", name, value, options);
  Cookies.set(name, value, options);
};

export const getCookie = (name) => {
    console.log("Getting cookie: ", name);
    console.log("Getting cookie value: ", Cookies.get(name));

  return Cookies.get(name);
};

export const deleteCookie = (name) => {
    console.log("Deleting cookie: ", name);
  Cookies.remove(name);
};

export const setCookieWithExpiry = (name, value, days) => {
  Cookies.set(name, value, { expires: days });
};