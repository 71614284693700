

const ErrorDefault = ({message}) => {
    //const sampleMessage = "The URL is expired please contact the finance broker for further help. ";

    return (
        <>
            <div id="kt_content_container" className="container-xxl" data-select2-id="select2-data-kt_content_container">
                {
                    message !== undefined && message !== "" ?
                        <div style={{ display: "flex", height: "500px" }} className="h-lg-450px" >
                            <div style={{ padding: "auto", "margin": "auto", padding: "20px", backgroundColor:"white" }}>

                            <div style={{fontSize:"20px"}}>{message}</div>
                            </div>
                        </div>
                        :
                        <img src="/media/illustrations/dozzy-1/18-dark.png" alt="" style={{ maxWidth: "100%" }} className="h-lg-450px" />
                }



            </div>
        </>
    )
}

export default ErrorDefault;