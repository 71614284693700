

//files type : license, payslips, bank statements, etc
//ASK THE USER TO PUT THE FILE TYPE AND THEN UPLOAD THE FILE

import React, { useEffect, useState } from 'react';
import UploadFile from './UploadFile';
import 'primereact/resources/themes/lara-light-cyan/theme.css';

const Files = (props) => {
    const [tokenInfo, setTokenInfo] = useState({ applicationId: "", applicantId: 0, token: "" });

    //READ TOKEN 
    const params = new URLSearchParams(window.location.search);
    const base64Token = params.get('token');
    console.log("base64Token:: ", base64Token);

    useEffect(() => {
        const decodeToken = () => {
            try {
                if (base64Token !== null && base64Token !== undefined && base64Token !== "" && base64Token) {
                    let decodedBase64Token = JSON.parse(atob(base64Token));

                    //SET TOKEN INFO
                    setTokenInfo({
                        applicationId: decodedBase64Token.applicationId,
                        applicantId: decodedBase64Token.applicantId,
                        token: decodedBase64Token.token
                    });
                }
            }
            catch (e) { }
        }
        decodeToken();
    }, []);

    return (
        <>

            <div className="container-xxl">

                <div className="card card-flush mt-8">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="card-title">
                                <h2 className="mb-0 mt-2">Upload Files</h2>
                            </div>
                            <div>
                                <p className="text-sm">Please upload all necessary documents, which include your driver's license, your two most recent payslips, and your Medicare card.</p>
                            </div>
                        </div>
                    </div>

                    {/***********FILES BODY ***********/}

                    <div className="card-body">                         
                        <div className='row'><div className='col-sm-12'>
                            <UploadFile
                                applicationId={tokenInfo.applicationId}
                                applicantId={tokenInfo.applicantId}
                                token={tokenInfo.token}
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Files;