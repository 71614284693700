import axios from '../api/axios';

const uploadFile = async(token, applicationId  , applicantId, fileObj) => {
    try {
        console.log(` public/uploadFile/${applicationId}/${applicantId}`, token);
        console.log("fileObj:: ", fileObj);

      const response = await axios.post(`public/uploadFile/${applicationId}/${applicantId}`, fileObj, { headers: { Authorization: "Bearer " + token , "api-key" : process.env.REACT_APP_API_KEY } });
      return response.data ? response.data : [];
    }
    catch (e) {
      if (e.response) {
        return { "error": 1, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data }
      }
    }
  }

export { uploadFile}