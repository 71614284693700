import axios from '../api/axios';


//optionalUrlParams  shoudl be passed as  ?paramName=value&paramName2=value2
const createConsumerApplication = async (token, applicationId  , applicantId, applicationPayload) => {
  try {
    console.log("endpoint ---- ", `public/application/${applicationId}/${applicantId}`);
    const response = await axios.post(`public/application/${applicationId}/${applicantId}`, applicationPayload, { headers: { Authorization: "Bearer " + token , "api-key" : process.env.REACT_APP_API_KEY } });
    
    
    return { error: 0,  httpCode: response.status, message : "Createds successfully", application : response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, message: e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

/*GET SINGLE APPLICATION*/
const getApplication = async (applicationId, applicantId, token) => {
  try {
    const response = await axios.get(`public/application/${applicationId}/${applicantId}`, { headers: { Authorization: "Bearer " + token, "api-key" : process.env.REACT_APP_API_KEY } });
    console.log("response --------------------", response.data);
    return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      
      
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: {} }
    }
  }
}


/*GET SINGLE APPLICATION MATCH IF EXISTS*/
const getApplicationMatch = async (id, token) => {
  try {
    const response = await axios.get(`applications/findApplicantMatch/${id}`, { headers: { Authorization: "Bearer " + token } });
    return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      
      
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: {} }
    }
  }
}

/*GET SINGLE APPLICATION OVERVIEW */
const getSignleApplicationOverview = async (id, token) => {
  try {
    const response = await axios.get(`applications/applicationOverview/${id}`, { headers: { Authorization: "Bearer " + token } });
    return { error: 0, httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      
      
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: {} }
    }
  }
}

/* LIST ALL CREDIT REP AGAINST THIS ENTITY ID(COMPANY ID) */
const getApplicationsList = async (token, query) => {
  try {
    const response = await axios.get(`applications/listing/${query}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0 , httpCode: response.status, "message":  "", data: response.data ? response.data : [] }
   
  }
  catch (e) {
    if (e.response) {
      
      
      return { "error": 1,httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, applications: {} }
    }
  }
}

const updateApplication = async (id, data, token) => {

  try {
    const response = await axios.put(`applications/${id}`, data, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": "Updated successfully", application: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, application: {} }
    }
  }

}

//PERFORM ACTIONS ASSIGN ANALYST (assignAnalyst) | DELETE APPLICATION(deleteApplication) | COPY APPLICATION (copyApplication) | updateProgressStatus

const performApplicationAdditionalActions = async (token, actionName, id, data) => {

  try {
    const response = await axios.put(`applications/performAdditionalActions/${actionName}/${id}`, data, { headers: { Authorization: "Bearer " + token } });
    
    return { "error": 0, httpCode: response.status, "message": response.data.message || "Updated successfully", application : response.data.application || {} , data: response.data }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode:e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, application : {}  }
    }
  }

}

//GET ALL PROGRESS STATUS AGAINST USER ROLE
const getApplicationProgressStatus = async (token) => {
  try {
    const response = await axios.get(`applications/progressStatus`, { headers: { Authorization: "Bearer " + token } });
    return { error: 0, error: 0,  httpCode: response.status, data: response.data ? response.data : {} }
  }
  catch (e) {
    if (e.response) {
      
      
      return { error: 1, httpCode: e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data: {} }
    }
  }

}


//FETCH APPLICATION ASSIGNED REFERRER GROUP REFERRER, A REFERRER STAFF
const getApplicationReferrerDetails = async (token, id) => {

  try {
    const response = await axios.get(`applications/getApplicationReferrerDetails/${id}`, { headers: { Authorization: "Bearer " + token } });
    return { "error": 0, httpCode: response.status, "message": response.data.message || "Loaded successfully", data : response.data|| {} }
  }
  catch (e) {
    if (e.response) {
      return { "error": 1, httpCode:e.response.status, "message": e.response.data["message"] ? e.response.data["message"] : e.response.data, data : {}  }
    }
  }

}


//GET APPLICATIONS THAT ARE RECIEVED BUT NOT ASSIGNED TO ANALYST
const applicationsNotAssignedToAnalystAfterCertainTime = async(token) => {
  try {
    const response = await axios.get(`applications/applicationsNotAssignedToAnalystAfterCertainTime/`, { headers: { Authorization: "Bearer " + token } });
    return { error: 0,  httpCode: response.status, message : "Retrieved", applications : response.data.applications !==undefined ? response.data.applications: [] }
  }
  catch (e) {
    if (e.response) {
      
      
      return { "error": 1, httpCode: e.response.status, message: e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

/****************************************APPLICANT API ENDPOINTS ******************************************************/
const createApplicant = async(token, applicationId, applicant) =>{
  try {
    const response = await axios.post(`applications/applicant/${applicationId}`, applicant, { headers: { Authorization: "Bearer " + token } });
    
    
    return { error: 0,  httpCode: response.status, message : "Createds successfully", applicant : response.data }
  }
  catch (e) {
    if (e.response) {
      
      
      return { "error": 1, httpCode: e.response.status, message: e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}

//UPDATE APPLICANT
const updateApplicant = async(token, applicationId,applicantId, applicant) =>{
  try {
    const response = await axios.put(`applications/applicant/${applicationId}/${applicantId}`, applicant, { headers: { Authorization: "Bearer " + token } });
    
    
    return { error: 0,  httpCode: response.status, message : "Updated successfully", applicant : response.data }
  }
  catch (e) {
    if (e.response) {
      
      
      return { "error": 1, httpCode: e.response.status, message: e.response.data["message"] ? e.response.data["message"] : e.response.data }
    }
  }
}


export { createConsumerApplication, updateApplication, getApplication , getApplicationMatch , getSignleApplicationOverview, getApplicationsList, performApplicationAdditionalActions , getApplicationProgressStatus,
  applicationsNotAssignedToAnalystAfterCertainTime, 
  createApplicant , updateApplicant , getApplicationReferrerDetails};