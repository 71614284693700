import { capitalizeFirstLowercaseRest, capitalizeFirstOfEachWord } from '../../../helpers'
const EmailField = ({ fieldName, placeholder, fieldValue, setFieldValue, capitalizeFirstLetter = false, charactersLimit = 0 }) => {
    return (
        <>
            <input
                type="text"
                className="form-control"
                style={{ height: "35px", fontWeight: "400" }}
                placeholder={placeholder}
                aria-label={placeholder}
                value={fieldValue}
                onChange={(e) => {
                    var inputString = e.target.value;

                    // Remove specific words: "delete", "select", and "from"
                    inputString = inputString.replace(/\b(delete|select|from)\b/gi, '');

                    // Remove special characters, but allow spaces and valid email characters
                    inputString = inputString.replace(/[^a-zA-Z0-9\s@._-]/g, ' ');

                    // Replace multiple spaces with a single space and trim leading/trailing spaces
                    inputString = inputString.replace(/\s\s+/g, ' ').trim();

                    //LIMIT THE CHARACTERS TO THE PROVIDED LIMIT 
                    if (charactersLimit > 0 && inputString.length > charactersLimit) { inputString = inputString.slice(0, charactersLimit) }


                    //CAPITALIZE FIRST LETTER
                    if (capitalizeFirstLetter && inputString.length > 0) { inputString = capitalizeFirstOfEachWord(inputString) }
                    setFieldValue(fieldName, inputString)
                }}
            />
        </>
    )
}

export default EmailField; 